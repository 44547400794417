<template>
  <f7-page name="notifications" @page:init="loadRefresh"
  ptr @ptr:refresh="loadRefresh"
  infinite
    :infinite-distance="200"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <f7-navbar :title="$root.setLang('notifications')" :back-link="$root.setLang('back')"></f7-navbar>

    <f7-block-title>{{$root.setLang('your_notifications')}}
      <f7-button 
      :class="{button_reload:is_loading==true}"
       icon-f7="arrow_2_circlepath"  @click="loadRefresh()" style="width: 50px;float: right;"></f7-button></f7-block-title>
    <f7-list media-list>
    <f7-list-item
        v-for="(notification, index) in $f7.data.notifications"
        :key="index"
        :title="$root.setLanguageNotification(notification)"
        :after="`${(notification && notification.price)? $root.setPriceNotification(notification.price):''}`"
        :header="(notification && notification.service && notification.service.name)? notification.service.name: ''"
        :text="(notification)?formatDate(notification.created): ''"
        >
        <img slot="media" :src="getAvatar(notification)" width="30" />
        <!-- <div class="item-header">{{$root.setLanguageNotification(notification)}}</div> -->
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
        notifications: [],
        allowInfinite: true,
        showPreloader: true,
        is_loading: false,
        page: 2,
      };
    },
    methods: {
      intialized() {
        console.log('initialized')
      },
      // loadMore(){
      //   const self = this;
      //   console.log(self.$f7.data.setLanguage('key_lange', 'Order #{main_id} by {userApply} had been created.'))
      //   self.notifications = this.$f7.data.notifications;

      //   console.log(self.notifications)
      //   console.log('ok nha')
      // },

      onPageInit() {
        const self = this;
        self.$f7.data.notifications = [];
        self.loadRefresh();
      },
      loadRefresh(done) {
        const self = this;
        self.notifications = []
        self.is_loading = true;
        self.showPreloader = true;
        self.page = 1;
        // self.loadData();
        // done();
        self.loadMoreNotifications(self.page, function(notifications){
              if(notifications){
                if(notifications.length == 0){
                  self.showPreloader = false;
                }
                self.$f7.data.notifications = notifications;
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
              if(typeof done === "function"){
                done();
              }
            })
      },
      loadMore() {
        const self = this;
        if(self.showPreloader && !self.is_loading){
          self.is_loading = true;
          if (!self.allowInfinite) return;
          self.allowInfinite = false;
            if (self.$f7.data.notifications.length >= 5000) {
              self.showPreloader = false;
              return;
            }
            // self.loadData()
            self.loadMoreNotifications(self.page, function(notifications){
              if(notifications){
                if(notifications.length == 0){
                  self.showPreloader = false;
                }
                self.$f7.data.notifications = self.$f7.data.notifications.concat(notifications);
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
            })
            
        }
      },
      loadMoreNotifications(page, callback) {
        const self = this;
        var app = self.$f7;
        app.request.post(self.$root.$f7.params.apiurl, {action: 'notificationslist', supplier: self.$root.supplier, token: app.data.user.token, service_ids: self.$root.services.join(','),page: page}, function(data){
          var notifications = data.data;
          callback(notifications);
        },
        function(err){
          callback(false);
        },'json');
      },
      // setLanguageNotification(notification){
      //   const self = this;
      //   return self.$f7.data.setLanguageNotification(notification);//self.$f7.data.setLanguageNotification(notifications)
      // },
      getAvatar(notification){
        const self = this;
        if(notification){
          return self.$root.$f7.params.apiurlRoot+'/'+notification.userApply.profileImageURL
        }else{
          return '';
        }
      },
      formatDate(created){
        const self = this;
        var d = new Date(created);
        return self.$root.formatDate(d);
      },
      // setPriceNotification(price){
      //   const self = this;
      //   return self.$root.setPriceNotification(price);
      // }
    },
    mounted () {
    },
  };
</script>