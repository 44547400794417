
import HomePage from '../pages/home.vue';
import AboutPage from '../pages/about.vue';
import FormPage from '../pages/form.vue';
import CatalogPage from '../pages/catalog.vue';
import OrdersPage from '../pages/orders.vue';
import NotificationsPage from '../pages/notifications.vue';
import PaymentsPage from '../pages/payments.vue';
import ProductPage from '../pages/product.vue';
import SettingsPage from '../pages/settings.vue';
import ContactsPage from '../pages/contacts.vue';
import ServicesPage from '../pages/services.vue';
import AddfundPage from '../pages/addfund.vue';
import PromotionsPage from '../pages/promotions.vue';

import DynamicRoutePage from '../pages/dynamic-route.vue';
import MessagePage from '../pages/message.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/catalog/',
    component: CatalogPage,
  },
  {
    path: '/orders/',
    component: OrdersPage,
  },
  {
    path: '/notifications/',
    component: NotificationsPage,
  },
  {
    path: '/payments/',
    component: PaymentsPage,
  },
  {
    path: '/product/:id/',
    component: ProductPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },
  {
    path: '/contacts/',
    component: ContactsPage,
  },
  {
    path: '/services/',
    component: ServicesPage,
  },
  {
    path: '/promotions/',
    component: PromotionsPage,
  },
  {
    path: '/addfund/',
    component: AddfundPage,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/message/:orderId/',
    component: MessagePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Thịnh',
          lastName: 'Nguyễn',
          about: 'Chào bạn, hy vọng bạn hứng thú với website này!',
          links: [
            {
              title: 'Trang quản lý chính',
              url: 'https://fbvideoview.com',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
