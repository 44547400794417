var site = {
	name: "Búp mắt Tiktok",
	supplier: '650a9d9065ccfa362f9b6582',
	lang_default: 'vn',
	managerSite: 'https://www.tiktok.com/',
  	service_alias: 'live_tiktok',
	services: ["64de11aae30c95146e9cd73f"],
  	paymentData: {
        'Vietcombank' : {
			'status': true,
			'selected': true,//set default show
			'id': '0371000463333',
			'name': 'Phan Thi Thu Suong',
			'bank_name': 'Vietcombank',
			'min': 2.5,
			'auto': false,
        },
    },
    contacts: {
        'phone': {
            'id': '+84559949229',
            'name': 'Phone, Zalo:',
        },
        'email': {
          'id': 'tiktokcpanel@gmail.com',
          'name': 'Email:',
        },
    },
}
export default site;