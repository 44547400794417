<template>
<f7-app :params="f7params" :color-theme="$root.color_theme" :class="($root.darkTheme)?'theme-dark':''">

  <!-- Left panel with cover effect-->
  <f7-panel left reveal >
    <f7-view>
      <f7-page  @page:init="initPage">
        <f7-navbar title="">
          <f7-chip :text="$root.setLang('sign_out')" media-bg-color="red" @click="signout" class="panel-close">
            <f7-icon slot="media" ios="f7:power" aurora="f7:power" md="material:power_settings_new"></f7-icon>
          </f7-chip>
          <f7-link style="text-transform: uppercase;" popover-open=".popover-menu"><f7-chip :text="$root.lang"></f7-chip></f7-link>
          <f7-popover class="popover-menu">
            <f7-list>
              
              
              <f7-list-item popover-close ><f7-button popover-close @click="$root.changeLang('vn')" text="vn" style="width: 100%;"></f7-button></f7-list-item>
              <f7-list-item popover-close ><f7-button popover-close @click="$root.changeLang('us')" text="us" style="width: 100%;"></f7-button></f7-list-item>
            </f7-list>
          </f7-popover>
        </f7-navbar>
        <f7-block-title>{{$root.setLang('accounts')}}</f7-block-title>
        <f7-list simple-list>
          <f7-list-item :title="$root.setLang('change_password')"></f7-list-item>
          <f7-list-item :title="$root.setLang('change_email')"></f7-list-item>
          <f7-list-item :title="$root.setLang('change_avatar')"></f7-list-item>
        </f7-list>
        <f7-block-title>{{$root.setLang('guide')}}</f7-block-title>
        <f7-list simple-list>
          <f7-list-item :title="$root.setLang('add_fund')"></f7-list-item>
          <f7-list-item :title="$root.setLang('place_order')"></f7-list-item>
          <f7-list-item :title="$root.setLang('get_accesstoken')"></f7-list-item>
        </f7-list>
        <f7-list>
          <!-- <f7-list-item >
            <span>{{$root.setLang('dark_theme')}}</span>
            <f7-toggle 
            :checked="$root.darkTheme" 
            @toggle:change="$root.changeDark()"
            color="blue"></f7-toggle>
          </f7-list-item> -->
          <f7-list-item
          checkbox
          color="red"
            :title="$root.setLang('dark_theme')"
            :checked="$root.darkTheme"
            @change="$root.changeDark"
          >
        </f7-list-item>
  </f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Right panel with reveal effect-->
  <!-- <f7-panel right reveal >
    <f7-view>
      <f7-page>
        <f7-navbar :title="`Xin chào ${(user && user.username)? user.username: 'Khách'}`"></f7-navbar>
        <f7-block>
          <f7-chip text="Đăng xuất" media-bg-color="red" @click="signout" class="panel-close">
        <f7-icon slot="media" ios="f7:power" aurora="f7:power" md="material:power_settings_new"></f7-icon>
      </f7-chip>
        </f7-block>

      </f7-page>
    </f7-view>
  </f7-panel> -->


  <!-- Views/Tabs container -->
  <f7-views tabs class="safe-areas" v-if="!user && !is_signup">

    <f7-link popover-open=".popover-menu" style="z-index: 11;float: right;right: 10px;top: 10px;text-transform: uppercase;"><f7-chip :style="($root.darkTheme)?'':'background-color: #e7f1fe;'" :text="$root.lang"></f7-chip></f7-link>
              <f7-popover class="popover-menu">
                <f7-list>
                  
                  
                  <f7-list-item popover-close ><f7-button popover-close @click="$root.changeLang('vn')" text="vn" style="width: 100%;"></f7-button></f7-list-item>
                  <f7-list-item popover-close ><f7-button popover-close @click="$root.changeLang('us')" text="us" style="width: 100%;"></f7-button></f7-list-item>
                </f7-list>
              </f7-popover>
      <f7-page no-toolbar no-navbar no-swipeback login-screen :style="($root.darkTheme)?'':'background: #5800f5; background-image: -moz-radial-gradient(center 45deg,circle cover, #e0e0e0, #5800f5);background-image: -webkit-gradient(radial, 50% 50%, 0, 50% 50%,800, from(#e0e0e0), to(#5800f5));'">
        <f7-login-screen-title>{{f7params.name}}</f7-login-screen-title>
        <f7-block  v-if="is_have_name" style="text-align: center;">{{$root.setLang('hi')}} {{username}}, {{$root.setLang('enter_password_to_access').replace('{url}', url_move)}}</f7-block>
        <f7-block strong :style="((is_have_name)?'padding:0;':'')">
        <f7-list form :style="'background: transparent;'+((is_have_name)?'margin:0;':'')">
          <f7-list-input v-if="!is_have_name"
            :label="$root.setLang('username')"
            type="text"
            :placeholder="$root.setLang('username_placeholder')"
            :value="username"
            @input="username = $event.target.value"
          ></f7-list-input>
          <f7-list-input v-if="!is_have_name"
            :label="$root.setLang('password')"
            type="password"
            :placeholder="$root.setLang('password_placeholder')"
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
          <f7-list-input v-if="is_have_name"
            type="password"
            :placeholder="$root.setLang('password_placeholder')"
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
        </f7-list>
        </f7-block>
        <f7-list>
          <f7-list-button @click="signin">{{$root.setLang('sign_in')}}</f7-list-button>
            <f7-list-button @click="is_signup = true;" style="">{{$root.setLang('signup_button')}} {{$root.setLang('sign_up')}}</f7-list-button>
          <f7-block-footer>{{$root.setLang('sign_in_help')}}</f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-views>

  <f7-views tabs class="safe-areas" v-if="!user && is_signup">

    <f7-link popover-open=".popover-menu" style="z-index: 11;float: right;right: 10px;top: 10px;text-transform: uppercase;"><f7-chip :style="($root.darkTheme)?'':'background-color: #e7f1fe;'" :text="$root.lang"></f7-chip></f7-link>
              <f7-popover class="popover-menu">
                <f7-list>
                  
                  
                  <f7-list-item popover-close ><f7-button popover-close @click="$root.changeLang('vn')" text="vn" style="width: 100%;"></f7-button></f7-list-item>
                  <f7-list-item popover-close ><f7-button popover-close @click="$root.changeLang('us')" text="us" style="width: 100%;"></f7-button></f7-list-item>
                </f7-list>
              </f7-popover>
      <f7-page no-toolbar no-navbar no-swipeback login-screen :style="($root.darkTheme)?'':'background: #5800f5; background-image: -moz-radial-gradient(center 45deg,circle cover, #e0e0e0, #5800f5);background-image: -webkit-gradient(radial, 50% 50%, 0, 50% 50%,800, from(#e0e0e0), to(#5800f5));'">
        <f7-login-screen-title>{{f7params.name}}</f7-login-screen-title>
        <f7-block strong style="">
        <f7-list form style="background: transparent;">
          <f7-list-input
            :label="$root.setLang('username')"
            type="text"
            :placeholder="$root.setLang('username_placeholder')"
            :value="username"
            @input="username = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            :label="$root.setLang('password')"
            type="password"
            :placeholder="$root.setLang('password_placeholder')"
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            :label="$root.setLang('repassword')"
            type="password"
            :placeholder="$root.setLang('repassword_placeholder')"
            :value="repassword"
            @input="repassword = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            :label="$root.setLang('email')"
            type="text"
            :placeholder="$root.setLang('email_placeholder')"
            :value="email"
            @input="email = $event.target.value"
          ></f7-list-input>
        </f7-list>
      </f7-block>
        <f7-list>
            <f7-list-button @click="signup">{{$root.setLang('sign_up')}}</f7-list-button>
          <f7-list-button @click="is_signup=false;" style="">{{$root.setLang('signin_button')}} {{$root.setLang('sign_in')}}</f7-list-button>
          <f7-block-footer>{{$root.setLang('sign_in_help')}}</f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-views>
  <f7-views tabs class="safe-areas" v-if="user">
    <!-- Tabbar for switching views-tabs -->
    <f7-toolbar tabbar labels bottom :color-theme="$root.color_theme_app">
      <f7-link tab-link="#view-home" :class="last_page_name == 'home'?'tab-link-active':''"  @click="$root.changePage()" icon-ios="f7:house_fill" icon-aurora="f7:house_fill" icon-md="material:home" :text="$root.setLang('home')"></f7-link>
      <f7-link tab-link="#view-orders" :class="last_page_name == 'orders'?'tab-link-active':''" @click="$root.changePage()" icon-ios="f7:square_list_fill" icon-aurora="f7:square_list_fill" icon-md="material:view_list" :text="$root.setLang('orders')"></f7-link>
      <f7-link tab-link="#view-new" icon-ios="f7:plus" icon-aurora="f7:plus" icon-md="material:add" :text="$root.setLang('new_order')" @click="openLiveview"></f7-link>
      <f7-link tab-link="#view-payments" :class="last_page_name == 'payments'?'tab-link-active':''" @click="$root.changePage()">
        <f7-icon ios="f7:money_dollar" aurora="f7:money_dollar" md="f7:money_dollar">
        </f7-icon>
        <span class="tabbar-label">{{$root.setLang('transactions_history')}}</span>
      </f7-link>
      <!-- <f7-link tab-link="#view-settings" icon-ios="f7:gear" icon-aurora="f7:gear" icon-md="material:settings" :text="$root.setLang('settings')"></f7-link> -->
      <f7-link tab-link="#view-contacts" :class="last_page_name == 'contacts'?'tab-link-active':''" @click="$root.changePage()" icon-ios="f7:phone" icon-aurora="f7:phone" icon-md="material:call" :text="$root.setLang('contacts')"></f7-link>
    </f7-toolbar>

    <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
    <f7-view id="view-home" name="home" main tab :class="last_page_name == 'home'?'tab-active':''" url="/"></f7-view>

    <!-- Orders View -->
    <f7-view id="view-orders" name="orders" tab :class="last_page_name == 'orders'?'tab-active':''"  url="/orders/" @tab:show="getVideovieworders(1)"></f7-view>
    <!-- Notifications View -->
    <f7-view id="view-notifications" name="notifications" tab url="/notifications/" @tab:show="getNotifications"></f7-view>
    <f7-view id="view-payments" name="payments" tab :class="last_page_name == 'payments'?'tab-active':''"  url="/payments/" @tab:show="getPayments"></f7-view>
    <!-- Settings View -->
    <!-- <f7-view id="view-settings" name="settings" tab url="/settings/"></f7-view> -->
    <!-- Settings View -->
    <f7-view id="view-contacts" name="contacts" tab :class="last_page_name == 'contacts'?'tab-active':''"  url="/contacts/"></f7-view>

  </f7-views>

  <!--Popup view create begin-->
  <f7-popup class="buffview-popup" :opened="popupBuffview" @popup:closed="popupBuffview = false">
    <f7-view>
      <f7-page>
        <form class="list" id="FormBuffview" style="margin: 0;">
        <f7-navbar>
          <f7-nav-left>
            
            <f7-link popup-close>Đóng</f7-link>
            <!-- <f7-link icon-ios="f7:minus" icon-aurora="f7:minus" icon-md="f7:minus" popup-close></f7-link> -->
          </f7-nav-left>
          <f7-nav-title sliding>Đơn view lẻ</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="submitFormBuffview">Gửi</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list no-hairlines-md>
          <f7-list-input
             label="Liên kết video"
            type="url"
            name="url"
            placeholder="Liên kết video: https://www.facebook.com/amazing/videos/123456789/"
            clear-button
          ></f7-list-input>
          <f7-list-input
            label="Số view"
            type="number"
            name="amount"
            placeholder="Số view nhỏ nhất là 500"
            clear-button
            @input="getDataForm('#FormBuffview')"
            @change="getDataForm('#FormBuffview')"
          ></f7-list-input>
          <f7-list-item 
          title="Chọn loại view" smart-select :smart-select-params="{openIn: 'popup', popupCloseLinkText:'Đóng', closeOnSelect: true,searchbar: true, searchbarPlaceholder: 'Tìm kiếm'}">
            <select name="service_id" 
            required
            validate
            @change="getDataForm('#FormBuffview')">
              <option selected disabled>
              Chưa chọn
            </option>
              <option  v-for="(balanceitem, index) in userbalance.balances" :key="index" v-if="balanceitem.service_id.service_group == 'videoview' && checkServiceActive(balanceitem.service_id._id)"
              :value="balanceitem.service_id._id" 
              >
              {{balanceitem.service_id.name}}
            </option>
            </select>
          </f7-list-item>
      <f7-list-input
        type="textarea"
        label="Ghi chú"
        name="note"
        placeholder="Nhập vào ghi chú nếu có"
        resizable
            clear-button
      ></f7-list-input>
      <f7-list-input
            @input="changePromotion('#FormBuffview')"
        label="Mã khuyến mãi"
        type="text"
        name="promotion_code"
        placeholder="Nhập vào mã khuyến mãi"
            clear-button
      ></f7-list-input>
      <input
        type="hidden"
        name="promotion"
      ></input>
      <input
        type="hidden"
        name="service_type"
        value="videoview"
      ></input>
      <f7-block v-if="order_price">
        <f7-row>
          <f7-col>{{promotion_des}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>Bạn sẽ trả <span style="color: #ff6b22; font-weight: bold;">{{order_price}}</span><span style="text-decoration: line-through;font-style: italic;color: #8e8e93;" v-if="order_price != order_price_root"> ({{order_price_root}})</span> cho đơn hàng này.</f7-col>
        </f7-row>
        
        
      </f7-block>
        </f7-list>
      </form>
      </f7-page>
    </f7-view>
    </f7-popup>
    <!--Popup view create end-->
    <!--Popup 30k view 1m begin-->
    <f7-popup class="buffview1m-popup" :opened="popupBuffview1m" @popup:opened="onPopupView1m" @popup:closed="popupBuffview1m = false">
    <f7-view>
      <f7-page>

        <form class="list" id="FormBuffview1m" style="margin: 0;">
        <f7-navbar>
          <f7-nav-left>
            
            <f7-link popup-close>Đóng</f7-link>
            <!-- <f7-link icon-ios="f7:minus" icon-aurora="f7:minus" icon-md="f7:minus" popup-close></f7-link> -->
          </f7-nav-left>
          <f7-nav-title sliding>Đơn 30k lượt xem 1 phút</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="submitFormBuffview1m">Gửi</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list no-hairlines-md>
          <f7-block>
        <f7-row>
          <f7-col>Lưu ý: Thời lượng video của bạn phải trên 3 phút mới đủ điều kiện bật xanh bước 2</f7-col>
        </f7-row>
        
        
      </f7-block>
          <f7-list-input
             label="Liên kết video"
            type="url"
            name="url"
            placeholder="Liên kết video: https://www.facebook.com/amazing/videos/123456789/"
            clear-button
          ></f7-list-input>
          <!-- <f7-list-input
            label="Số view"
            type="hidden"
            name="amount"
            placeholder="Số view nhỏ nhất là 500"
            clear-button
            value="30000"
            @input="getDataForm('#FormBuffview1m')"
            @change="getDataForm('#FormBuffview1m')"
          ></f7-list-input> -->
          <!-- <f7-list-item 
          title="Chọn loại view" smart-select :smart-select-params="{openIn: 'popup', popupCloseLinkText:'Đóng', closeOnSelect: true,searchbar: true, searchbarPlaceholder: 'Tìm kiếm'}">
            <select name="service_id" 
            required
            validate
            @change="getDataForm('#FormBuffview1m')">
              <option selected disabled>
              Chưa chọn
            </option>
              <option  v-for="(balanceitem, index) in userbalance.balances" :key="index" v-if="balanceitem.service_id.service_group == 'videoview' && checkServiceActive(balanceitem.service_id._id)"
              :value="balanceitem.service_id._id" 
              >
              {{balanceitem.service_id.name}}
            </option>
            </select>
          </f7-list-item> -->
          <input
        type="hidden"
        name="is_view1m"
        value="true"
      ></input>
          <input
        type="hidden"
        name="amount"
        value="30000"
      ></input>
          <input
        type="hidden"
        name="service_id"
        value=""
      ></input>
      <f7-list-input
        type="textarea"
        label="Ghi chú"
        name="note"
        placeholder="Nhập vào ghi chú nếu có"
        resizable
            clear-button
      ></f7-list-input>
      <f7-list-input
            @input="changePromotion('#FormBuffview1m')"
        label="Mã khuyến mãi"
        type="text"
        name="promotion_code"
        placeholder="Nhập vào mã khuyến mãi"
            clear-button
      ></f7-list-input>
      <input
        type="hidden"
        name="promotion"
      ></input>
      <input
        type="hidden"
        name="service_type"
        value="videoview"
      ></input>
      <f7-block v-if="order_price">
        <f7-row>
          <f7-col>{{promotion_des}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>Bạn sẽ trả <span style="color: #ff6b22; font-weight: bold;">{{order_price}}</span><span style="text-decoration: line-through;font-style: italic;color: #8e8e93;" v-if="order_price != order_price_root"> ({{order_price_root}})</span> cho đơn hàng này.</f7-col>
        </f7-row>
        
        
      </f7-block>
        </f7-list>
      </form>
      </f7-page>
    </f7-view>
    </f7-popup>
    <!--Popup 30k view 1m end-->
    <!--Popup 600k for video 123 minutes begin-->
    <f7-popup class="buffview600k2h-popup" :opened="popupBuffview600k2h" @popup:opened="onPopupView600k2h" @popup:closed="popupBuffview600k2h = false">
    <f7-view>
      <f7-page>

        <form class="list" id="FormBuffview600k2h" style="margin: 0;">
        <f7-navbar>
          <f7-nav-left>
            
            <f7-link popup-close>{{$root.setLang('close')}}</f7-link>
          </f7-nav-left>
          <f7-nav-title sliding>{{$root.setLang('title_600k')}}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="submitFormBuffview600k2h">{{$root.setLang('send')}}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list no-hairlines-md>
          <f7-block>
        <f7-row>
          <f7-col>{{$root.setLang('des_600k')}}</f7-col>
        </f7-row>
        
        
      </f7-block>
          <f7-list-input
          @input="changeUrl('#FormBuffview600k2h')"
          type="textarea"
             :label="$root.setLang('video_url')"
            name="url"
            :placeholder="$root.setLang('video_url_placeholder')"
            resizable
            clear-button
          ><i slot="label" style="font-size: 20px" class="icon f7-icons icon-tooltip-url-ab if-not-md">info_circle_fill</i><i slot="label" style="font-size: 20px;color: #ff6b22;font-weight: bold;" class="icon f7-icons icon-tooltip-required if-not-md">*</i> </f7-list-input>
      <f7-list-input
        type="textarea"
        :label="$root.setLang('accesstoken')"
        name="accesstoken"
        v-model="myaccesstoken"
        :placeholder="$root.setLang('accesstoken_placeholder')"
        resizable
            clear-button
      ><i slot="label" style="font-size: 20px;color: #ff6b22;font-weight: bold;" class="icon f7-icons icon-tooltip-required if-not-md">*</i></f7-list-input>
          <input
        type="hidden"
        name="is_view600k2h"
        value="true"
      ></input>
          <input
        type="hidden"
        name="amount"
        value="5100"
      ></input>
          <input
        type="hidden"
        name="service_id"
        value=""
      ></input>
      <f7-list-input
        type="textarea"
        :label="$root.setLang('note')"
        name="note"
        :placeholder="$root.setLang('note_placeholder')"
        resizable
            clear-button
      ></f7-list-input>
      <f7-list-input
            @input="changePromotion('#FormBuffview600k2h')"
        :label="$root.setLang('discount_code')"
        type="text"
        name="promotion_code"
        :placeholder="$root.setLang('discount_code_placeholder')"
            clear-button
      ></f7-list-input>
      <f7-block-title v-if="promotions.length > 0">{{$root.setLang('click_the_line_to_apply_discount_code')}}</f7-block-title>
      <f7-list media-list>
        <f7-list-item
            v-for="(promotion, index) in promotions"
            :key="index"
            :subtitle="``"
            :title="promotion.code"
            :text="$root.setLang('expiry_date_until_end_of_day')+` `+$root.formatDatePromotion(promotion.expired_date)"
            :badge="$root.getCoverPromotion(promotion)"
            badge-color="deeporange"
            @click="applyPromotion('#FormBuffview600k2h',promotion.code)"
            >
          </f7-list-item>
        </f7-list>
      <input
        type="hidden"
        name="promotion"
      ></input>
      <input
        type="hidden"
        name="service_type"
        value="videoview"
      ></input>
      <f7-block v-if="order_price">
        <f7-row>
          <f7-col>{{promotion_des}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>{{$root.setLang('you_will_pay')}} <span style="color: #ff6b22; font-weight: bold;">{{order_price}}</span><span style="text-decoration: line-through;font-style: italic;color: #8e8e93;" v-if="order_price != order_price_root"> ({{order_price_root}})</span> {{$root.setLang('for_this_order')}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col><span style="color: #ff6b22; font-weight: bold;">{{$root.setLang('how_to_get_accesstoken')}}: </span>{{$root.setLang('get_accesstoken_guide')}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <f7-link :href="$root.setLang('get_accesstoken_guide_url')" class="button" target="_blank" style="font-size: 13px;display: inline-block;
                border: 1px solid #e3e3e3;
                background: #fff;" external>{{$root.setLang('go_to_get_access_token')}}</f7-link> {{$root.setLang('or')}} 
            <f7-button v-clipboard:copy="$root.setLang('get_accesstoken_guide_url')"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  style="    display: inline-block;
                border: 1px solid #e3e3e3;
                background: #fff;"
                  >Copy URL</f7-button>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col><span style="color: #ff6b22; font-weight: bold;">{{$root.setLang('how_to_get_accesstoken_console')}}: </span>{{$root.setLang('get_accesstoken_guide_console')}}</f7-col>
        </f7-row>
        <f7-row>
            <f7-button v-clipboard:copy="$root.setLang('js_access_token')"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  style="    display: inline-block;
                border: 1px solid #e3e3e3;
                background: #fff;"
                  >Copy Code CONSOLE</f7-button>
        </f7-row>
        
      </f7-block>
        </f7-list>
      </form>
      </f7-page>
    </f7-view>
    </f7-popup>
    <!--Popup 600k for video 123 minutes end-->

    <!--Popup 600klive begin-->
    <f7-popup class="buffview600klive-popup" :opened="popupBuffview600klive" @popup:opened="onPopupView600klive" @popup:closed="popupBuffview600klive = false">
    <f7-view>
      <f7-page>

        <form class="list" id="FormBuffview600klive" style="margin: 0;">
        <f7-navbar>
          <f7-nav-left>
            
            <f7-link popup-close>{{$root.setLang('close')}}</f7-link>
          </f7-nav-left>
          <f7-nav-title sliding>{{$root.setLang('title_600k_live')}}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="submitFormBuffview600klive">{{$root.setLang('send')}}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list no-hairlines-md>
          <f7-block>
        <f7-row>
          <f7-col>{{$root.setLang('des_600k_live')}}</f7-col>
        </f7-row>
        
        
      </f7-block>
          <f7-list-input
          @input="changeUrl('#FormBuffview600klive')"
             type="textarea"
             :label="$root.setLang('video_url')"
            name="url"
            :placeholder="$root.setLang('video_url_placeholder')"
            resizable
            clear-button
          ><i slot="label" style="font-size: 20px" class="icon f7-icons icon-tooltip-url-ab if-not-md">info_circle_fill</i><i slot="label" style="font-size: 20px;color: #ff6b22;font-weight: bold;" class="icon f7-icons icon-tooltip-required if-not-md">*</i> </f7-list-input>
      <f7-list-input
        type="textarea"
        :label="$root.setLang('accesstoken')"
        name="accesstoken"
        v-model="myaccesstoken"
        :placeholder="$root.setLang('accesstoken_placeholder')"
        resizable
            clear-button
      ><i slot="label" style="font-size: 20px;color: #ff6b22;font-weight: bold;" class="icon f7-icons icon-tooltip-required if-not-md">*</i></f7-list-input>
          <input
        type="hidden"
        name="is_view600klive"
        value="true"
      ></input>
          <input
        type="hidden"
        name="amount"
        value="5100"
      ></input>
          <input
        type="hidden"
        name="service_id"
        value=""
      ></input>
      <f7-list-input
        type="textarea"
        :label="$root.setLang('note')"
        name="note"
        :placeholder="$root.setLang('note_placeholder')"
        resizable
            clear-button
      ></f7-list-input>
      <f7-list-input
            @input="changePromotion('#FormBuffview600klive')"
        :label="$root.setLang('discount_code')"
        type="text"
        name="promotion_code"
        :placeholder="$root.setLang('discount_code_placeholder')"
            clear-button
      ></f7-list-input>
      <f7-block-title v-if="promotions.length > 0">{{$root.setLang('click_the_line_to_apply_discount_code')}}</f7-block-title>
      <f7-list media-list>
        <f7-list-item
            v-for="(promotion, index) in promotions"
            :key="index"
            :subtitle="``"
            :title="promotion.code"
            :text="$root.setLang('expiry_date_until_end_of_day')+` `+$root.formatDatePromotion(promotion.expired_date)"
            :badge="$root.getCoverPromotion(promotion)"
            badge-color="deeporange"
            @click="applyPromotion('#FormBuffview600klive',promotion.code)"
            >
          </f7-list-item>
        </f7-list>
      <input
        type="hidden"
        name="promotion"
      ></input>
      <input
        type="hidden"
        name="service_type"
        value="videoview"
      ></input>
      <f7-block v-if="order_price">
        <f7-row>
          <f7-col>{{promotion_des}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>{{$root.setLang('you_will_pay')}} <span style="color: #ff6b22; font-weight: bold;">{{order_price}}</span><span style="text-decoration: line-through;font-style: italic;color: #8e8e93;" v-if="order_price != order_price_root"> ({{order_price_root}})</span> {{$root.setLang('for_this_order')}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col><span style="color: #ff6b22; font-weight: bold;">{{$root.setLang('how_to_get_accesstoken')}}: </span>{{$root.setLang('get_accesstoken_guide')}}</f7-col>
        </f7-row>
        
        <f7-row>
          <f7-col>
            <f7-link :href="$root.setLang('get_accesstoken_guide_url')" class="button" target="_blank" style="font-size: 13px;display: inline-block;
                border: 1px solid #e3e3e3;
                background: #fff;" external>{{$root.setLang('go_to_get_access_token')}}</f7-link> {{$root.setLang('or')}} 
            <f7-button v-clipboard:copy="$root.setLang('get_accesstoken_guide_url')"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  style="    display: inline-block;
                border: 1px solid #e3e3e3;
                background: #fff;"
                  >Copy URL</f7-button>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col><span style="color: #ff6b22; font-weight: bold;">{{$root.setLang('how_to_get_accesstoken_console')}}: </span>{{$root.setLang('get_accesstoken_guide_console')}}</f7-col>
        </f7-row>
        <f7-row>
            <f7-button v-clipboard:copy="$root.setLang('js_access_token')"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  style="    display: inline-block;
                border: 1px solid #e3e3e3;
                background: #fff;"
                  >Copy Code CONSOLE</f7-button>
        </f7-row>
      </f7-block>
        </f7-list>
      </form>
      </f7-page>
    </f7-view>
    </f7-popup>
    <!--Popup 600klive end-->

    <!--Popup 60klive begin-->
    <f7-popup class="buffview60klive-popup" :opened="popupBuffview60klive" @popup:opened="onPopupView60klive" @popup:closed="popupBuffview60klive = false">
    <f7-view>
      <f7-page>

        <form class="list" id="FormBuffview60klive" style="margin: 0;">
        <f7-navbar>
          <f7-nav-left>
            
            <f7-link popup-close>{{$root.setLang('close')}}</f7-link>
          </f7-nav-left>
          <f7-nav-title sliding>{{$root.setLang('title_60k_live')}}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="submitFormBuffview60klive">{{$root.setLang('send')}}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list no-hairlines-md>
          <f7-block>
        <f7-row>
          <f7-col>{{$root.setLang('des_60k_live')}}</f7-col>
        </f7-row>
        
        
      </f7-block>
          <f7-list-input
          @input="changeUrl('#FormBuffview60klive')"
             type="textarea"
             :label="$root.setLang('video_url')"
            name="url"
            :placeholder="$root.setLang('video_url_placeholder')"
            resizable
            clear-button
          ><i slot="label" style="font-size: 20px" class="icon f7-icons icon-tooltip-url-ab if-not-md">info_circle_fill</i><i slot="label" style="font-size: 20px;color: #ff6b22;font-weight: bold;" class="icon f7-icons icon-tooltip-required if-not-md">*</i> </f7-list-input>
      <f7-list-input
        type="textarea"
        :label="$root.setLang('accesstoken')"
        name="accesstoken"
        v-model="myaccesstoken"
        :placeholder="$root.setLang('accesstoken_placeholder')"
        resizable
            clear-button
      ><i slot="label" style="font-size: 20px;color: #ff6b22;font-weight: bold;" class="icon f7-icons icon-tooltip-required if-not-md">*</i></f7-list-input>
          <input
        type="hidden"
        name="is_view60klive"
        value="true"
      ></input>
          <input
        type="hidden"
        name="amount"
        value="1000"
      ></input>
          <input
        type="hidden"
        name="service_id"
        value=""
      ></input>
      <f7-list-input
        type="textarea"
        :label="$root.setLang('note')"
        name="note"
        :placeholder="$root.setLang('note_placeholder')"
        resizable
            clear-button
      ></f7-list-input>
      <f7-list-input
            @input="changePromotion('#FormBuffview60klive')"
        :label="$root.setLang('discount_code')"
        type="text"
        name="promotion_code"
        :placeholder="$root.setLang('discount_code_placeholder')"
            clear-button
      ></f7-list-input>
      <f7-block-title v-if="promotions.length > 0">{{$root.setLang('click_the_line_to_apply_discount_code')}}</f7-block-title>
      <f7-list media-list>
        <f7-list-item
            v-for="(promotion, index) in promotions"
            :key="index"
            :subtitle="``"
            :title="promotion.code"
            :text="$root.setLang('expiry_date_until_end_of_day')+` `+$root.formatDatePromotion(promotion.expired_date)"
            :badge="$root.getCoverPromotion(promotion)"
            badge-color="deeporange"
            @click="applyPromotion('#FormBuffview60klive',promotion.code)"
            >
          </f7-list-item>
        </f7-list>
      <input
        type="hidden"
        name="promotion"
      ></input>
      <input
        type="hidden"
        name="service_type"
        value="videoview"
      ></input>
      <f7-block v-if="order_price">
        <f7-row>
          <f7-col>{{promotion_des}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>{{$root.setLang('you_will_pay')}} <span style="color: #ff6b22; font-weight: bold;">{{order_price}}</span><span style="text-decoration: line-through;font-style: italic;color: #8e8e93;" v-if="order_price != order_price_root"> ({{order_price_root}})</span> {{$root.setLang('for_this_order')}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col><span style="color: #ff6b22; font-weight: bold;">{{$root.setLang('how_to_get_accesstoken')}}: </span>{{$root.setLang('get_accesstoken_guide')}}</f7-col>
        </f7-row>
        
        <f7-row>
          <f7-col>
            <f7-link :href="$root.setLang('get_accesstoken_guide_url')" class="button" target="_blank" style="font-size: 13px;display: inline-block;
                border: 1px solid #e3e3e3;
                background: #fff;" external>{{$root.setLang('go_to_get_access_token')}}</f7-link> {{$root.setLang('or')}} 
            <f7-button v-clipboard:copy="$root.setLang('get_accesstoken_guide_url')"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  style="    display: inline-block;
                border: 1px solid #e3e3e3;
                background: #fff;"
                  >Copy URL</f7-button>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col><span style="color: #ff6b22; font-weight: bold;">{{$root.setLang('how_to_get_accesstoken_console')}}: </span>{{$root.setLang('get_accesstoken_guide_console')}}</f7-col>
        </f7-row>
        <f7-row>
            <f7-button v-clipboard:copy="$root.setLang('js_access_token')"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  style="    display: inline-block;
                border: 1px solid #e3e3e3;
                background: #fff;"
                  >Copy Code CONSOLE</f7-button>
        </f7-row>
      </f7-block>
        </f7-list>
      </form>
      </f7-page>
    </f7-view>
    </f7-popup>
    <!--Popup 60klive end-->
    <!--Popup buff liveview create begin-->
  <f7-popup class="buffliveview-popup" :opened="popupBuffliveview" @popup:closed="popupBuffliveview = false">
    <f7-view>
        <f7-navbar>
          <f7-nav-left>
            
            <f7-link popup-close>{{$root.setLang('close')}}</f7-link>
          </f7-nav-left>
          <f7-nav-title sliding>{{$root.setLang('title_livestream_viewer')}}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="submitFormBuffliveview">{{$root.setLang('send')}}</f7-link>
          </f7-nav-right>
        </f7-navbar>
      <f7-page>
        <form class="list" id="FormBuffliveview" style="margin: 0;">
        <f7-list no-hairlines-md>
          <f7-block-title style="display: none;"></f7-block-title>
          <!-- <f7-block-title v-if="$root.viewersavailable != -1">{{$root.setLang('available')+' '}}<span style="color: #ff6b22; font-weight: bold;">{{$root.viewersavailable}}</span>{{' '+$root.setLang('viewers')}}</f7-block-title>
          <f7-block-title v-if="$root.viewersavailable == -1 && $f7 && $f7.data && $f7.data.user && $f7.data.user.roles.indexOf('admin') != -1">{{$root.setLang('available')+' '}}<span style="color: #ff6b22; font-weight: bold;">{{$root.viewersavailable_admin}}</span>{{' '+$root.setLang('viewers')}}</f7-block-title>
          <f7-block-title v-if="$root.viewersavailable == -1  && $f7 && $f7.data && $f7.data.user && $f7.data.user.roles.indexOf('admin') == -1">{{$root.setLang('viewers_not_available')}}</f7-block-title> -->
          <f7-block-title v-html="$root.getViewerAvailable()"></f7-block-title>
          

          <f7-list-input
            type="url"
            name="url"
            :label="$root.setLang('video_livestream_url')"
            :placeholder="$root.setLang('video_livestream_url_placeholder')"
            clear-button
          >
            <i slot="label" style="font-size: 20px" class="icon f7-icons icon-tooltip-url if-not-md">info_circle_fill</i>
          </f7-list-input>

          
          <f7-list-input
            type="number"
            name="amount"
            :label="$root.setLang('amount_viewer')"
            :placeholder="$root.setLang('amount_viewer_placeholder')"
            clear-button
            @input="getDataForm('#FormBuffliveview')"
            @change="getDataForm('#FormBuffliveview')"
          ></f7-list-input>
          <f7-list-item
          ref="num_minutes"
          :title="$root.setLang('num_minutes')" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
            <select name="num_minutes" 
            required
            validate
            @change="getDataForm('#FormBuffliveview')">
              <option  v-for="(num_minuteitem, index) in initnum_minutes()" :key="index" 
              :value="num_minuteitem.value"
              :selected="index == 0"
              >
              {{num_minuteitem.name}}
            </option>

            </select>
          </f7-list-item>
          <f7-list-item style="display: none;"
          title="View type" smart-select :smart-select-params="{openIn: 'popup', closeOnSelect: true,searchbar: true, searchbarPlaceholder: 'Search view type'}">
            <select name="service_id" 
            required
            validate
            @change="getDataForm('#FormBuffliveview')">
              <!-- <option selected disabled>
              None
            </option> -->
              <option  v-for="(balanceitem, index) in userbalance.balances" :key="index" v-if="balanceitem.service_id.service_group == 'liveview' && balanceitem.service_id.id == 11"
              :value="balanceitem.service_id._id"
              selected
              >
              {{balanceitem.service_id.name}}
            </option>
            </select>
          </f7-list-item>
      <f7-list-input
        type="textarea"
        :label="$root.setLang('note')"
        name="note"
        :placeholder="$root.setLang('note_placeholder')"
        resizable
            clear-button
      ></f7-list-input>
      <!-- <f7-list-input
            @input="changePromotion('#FormBuffliveview')"
        :label="$root.setLang('discount_code')"
        type="text"
        name="promotion_code"
        :placeholder="$root.setLang('discount_code_placeholder')"
            clear-button
      ></f7-list-input>
      <f7-block-title v-if="promotions.length > 0">{{$root.setLang('click_the_line_to_apply_discount_code')}}</f7-block-title>
      <f7-list media-list>
        <f7-list-item
            v-for="(promotion, index) in promotions"
            :key="index"
            :subtitle="``"
            :title="promotion.code"
            :text="$root.setLang('expiry_date_until_end_of_day')+` `+$root.formatDatePromotion(promotion.expired_date)"
            :badge="$root.getCoverPromotion(promotion)"
            badge-color="deeporange"
            @click="applyPromotion('#FormBuffliveview',promotion.code)"
            >
          </f7-list-item>
        </f7-list> -->
      <input
        type="hidden"
        name="promotion"
      ></input>
      <input
        type="hidden"
        name="service_type"
        value="liveview"
      ></input>
      <f7-block v-if="order_price">
        <f7-row>
          <f7-col>{{promotion_des}}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>{{$root.setLang('you_will_pay')}} <span style="color: #ff6b22; font-weight: bold;">{{order_price}}</span><span style="text-decoration: line-through;font-style: italic;color: #8e8e93;" v-if="order_price != order_price_root"> ({{order_price_root}})</span> {{$root.setLang('for_this_order')}}</f7-col>
        </f7-row>
        
        
      </f7-block>
        </f7-list>
      </form>
      </f7-page>
    </f7-view>
    </f7-popup>
    <!--Popup buff liveview create end-->

  <f7-login-screen id="my-login-screen">
    <f7-view>
      <f7-page login-screen>
        <f7-login-screen-title>Login</f7-login-screen-title>
        <f7-list form>
          <f7-list-input
            type="text"
            name="username"
            placeholder="Your username"
            :value="username"
            @input="username = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            type="password"
            name="password"
            placeholder="Your password"
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-list-button title="Sign In" login-screen-close @click="signin"></f7-list-button>
          <f7-block-footer>
            Some text about login information.<br>Click "Sign In" to close Login Screen
          </f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
  <!-- Messenger Plugin chat Code -->
    <div id="fb-root"></div>

    <!-- Your Plugin chat code -->
    <div id="fb-customer-chat" class="fb-customerchat">
    </div>
</f7-app>
</template>
<script>

  import routes from '../js/routes_live.js';

  export default {
    data() {
        const self = this;

      var check_havename = false;
      var params = (new URL(document.location)).searchParams;
      var username_get = "";
      if(params.get("u")){
        username_get = params.get("u");
        check_havename = true;
      }
      var url_move = '';
      if(params.get("s")){
        url_move = params.get("s");
      }
      var param_lang = self.$root.lang;

      if(params.get("l") && ["vn","us"].indexOf(params.get("l")) > -1){
        param_lang = params.get("l");
        // self.$root.changeLang(param_lang)
      }
      self.$root.lang = param_lang;
        // console.log('self',self)
      return {
              // myaccesstoken:'',
        // Framework7 Parameters
        f7params: {
          // name: 'AmazingCpanel', // App name
          name: self.$root.name, // App name
          theme: self.$root.theme,
              apiurlRoot: self.$root.apiurlRoot,
              apiurl: self.$root.apiurl,
          // App root data
          data: function () {
            return {

              // Demo products for Catalog section
              // apiurlRoot: 'http://localhost:8544/',
              // apiurl: 'http://localhost:8544/webapp',
              user: null,
              userStatic: {},
              userbalance: {},
              // apiurlRoot: 'https://api.amazingcpanel.com/',
              // apiurl: 'https://api.amazingcpanel.com/webapp',
              products: [
                {
                  id: '1',
                  title: 'Apple iPhone 8',
                  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi tempora similique reiciendis, error nesciunt vero, blanditiis pariatur dolor, minima sed sapiente rerum, dolorem corrupti hic modi praesentium unde saepe perspiciatis.'
                },
                {
                  id: '2',
                  title: 'Apple iPhone 8 Plus',
                  description: 'Velit odit autem modi saepe ratione totam minus, aperiam, labore quia provident temporibus quasi est ut aliquid blanditiis beatae suscipit odio vel! Nostrum porro sunt sint eveniet maiores, dolorem itaque!'
                },
                {
                  id: '3',
                  title: 'Apple iPhone X',
                  description: 'Expedita sequi perferendis quod illum pariatur aliquam, alias laboriosam! Vero blanditiis placeat, mollitia necessitatibus reprehenderit. Labore dolores amet quos, accusamus earum asperiores officiis assumenda optio architecto quia neque, quae eum.'
                },
              ],
              notifications: [],
              videovieworders: [],
              language: {},
              

            };
          },
          // App routes
          routes: routes,
        },

        // Login screen data
        username: username_get,
        email: '',
        password: '',
        repassword: '',
        user: null,
        popupBuffview: false,
        popupBuffview1m: false,
        popupBuffview600k2h: false,
        popupBuffview600klive: false,
        popupBuffview60klive: false,
        popupVipview: false,
        popupBuffliveview: false,
        popupVipliveview: false,
        userbalance: {},
        order_price: 0,
        order_price_root: 0,
        searchpromotionTimeout: null,
        promotion_des: "",
        promotions: [],
        last_page_name: 'home',
        is_signup: false,
        is_have_name: check_havename,
        url_move: url_move,
      }
    },
    methods: {
      initPage(){
        const self = this;
        const app = self.$f7;

        var last_page_name = app.form.getFormData('last_page_name');
        if(last_page_name){
          self.last_page_name = last_page_name;
        }
        // console.log(self.last_page_name)
        self.newMessage({
          title: 'Do binh',
          text: 'Sao chua len nhi',
          profileImageURL: self.$root.socket_url+'/modules/users/img/profile/default.png',
        })
        var navbarTooltip = app.tooltip.create({
          targetEl: '.icon-tooltip-url',
          text: self.$root.setLang('support_urls')+':<br>887123912387213<br>https://www.facebook.com/amazing/videos/887123912387213<br>https://www.facebook.com/887123912387213<br>https://www.facebook.com/watch/live/?v=887123912387213<br>https://m.facebook.com/story.php?story_fbid=5690198281059104&id=887123912387213<br>https://fb.watch/98Pxi3Mq9T<br>https://fb.gg/v/98Pxi3Mq9T/'
        });
        var navbarTooltip = app.tooltip.create({
          targetEl: '.icon-tooltip-url-ab',
          text: self.$root.setLang('support_urls')+':<br>https://www.facebook.com/amazing/videos/887123912387213<br>https://www.facebook.com/887123912387213<br>https://www.facebook.com/watch/?v=887123912387213<br>https://www.facebook.com/watch/live/?v=887123912387213<br>https://m.facebook.com/story.php?story_fbid=5690198281059104&id=887123912387213<br>https://fb.watch/98Pxi3Mq9T<br>https://fb.gg/v/98Pxi3Mq9T/'
        });
        var navbarTooltip = app.tooltip.create({
          targetEl: '.icon-tooltip-required',
          text: self.$root.setLang('required')
        });
        var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "111270840320072");
      chatbox.setAttribute("attribution", "biz_inbox");
      window.fbAsyncInit = function() {
        FB.init({
          xfbml            : true,
          version          : 'v12.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      },
      newMessage(data){
        // const self = this;
        // const app = self.$f7;
        // //self.$root.socket_url+'/'+message.user.profileImageURL
        // var notificationWithButton = app.notification.create({
        //   icon: '<div class="message-avatar" style="background-image: url(&quot;'+data.profileImageURL+'&quot;);width:100%;height:100%;"></div>',
        //   // title: app.name,
        //   title: data.title,
        //   // subtitle: data.subtitle,
        //   subtitle: data.text,
        //   // text: data.text,
        //   closeButton: true,
        // });
        // notificationWithButton.open();
      },
      initnum_minutes(){
        const self = this;
          var num_minutes  = [];
          for (var i = 1; i < 17; i++) {
            num_minutes.push({
              name: i*30 + ' '+self.$root.setLang('minutes'),
              value: i*30
            })
          }
          return num_minutes;
      },
      openLiveview(){
        const self = this;
        const app = self.$f7;
        // self.actionNew.close();
                  self.popupBuffliveview = true;
                  self.promotion_des = "";
                  var order = app.form.convertToData('#FormBuffliveview');
                  var num_minutes = 30;
                  if(order.num_minutes){
                    num_minutes = order.num_minutes;
                  }
                  self.getDataForm('#FormBuffliveview')
                  // self.getPromotionsApply(order.service_id);
                  self.initnum_minutes();
                  var textEl = self.$refs["num_minutes"].$el;
                  var myElement = textEl.querySelector('.item-after').innerHTML = num_minutes+' '+self.$root.setLang('minutes');
      },
      onPopupView1m(){
        const self = this;
        const app = self.$f7;
        var order = app.form.convertToData('#FormBuffview1m');
        order.amount = 30000;
        for (var i = 0; i < self.userbalance.balances.length; i++) {
          if(self.userbalance.balances[i].service_id.id == 3){
            order.service_id = self.userbalance.balances[i].service_id._id;
            break;
          }
        }
        app.form.fillFromData('#FormBuffview1m', order);
        self.getDataForm('#FormBuffview1m');
        
        // alert('ok')
      },
      onPopupView600k2h(){
        const self = this;
        const app = self.$f7;
        try{

          var order = app.form.convertToData('#FormBuffview600k2h');
          order.amount = 5100;
          for (var i = 0; i < self.userbalance.balances.length; i++) {
            if(self.userbalance.balances[i].service_id.id == 111){
              order.service_id = self.userbalance.balances[i].service_id._id;
              break;
            }
          }
          app.form.fillFromData('#FormBuffview600k2h', order);
          self.getDataForm('#FormBuffview600k2h');
          self.getPromotionsApply(order.service_id);
          // alert('ok')
        }catch(e){
          setTimeout(function(){
            self.onPopupView600k2h()
          },200)
        }
      },

      onPopupView600klive(){
        const self = this;
        try{
          const app = self.$f7;
          var order = app.form.convertToData('#FormBuffview600klive');
          order.amount = 5100;
          for (var i = 0; i < self.userbalance.balances.length; i++) {
            if(self.userbalance.balances[i].service_id.id == 68){
              order.service_id = self.userbalance.balances[i].service_id._id;
              break;
            }
          }
          app.form.fillFromData('#FormBuffview600klive', order);
          self.getDataForm('#FormBuffview600klive');
          self.getPromotionsApply(order.service_id);
          
          // alert('ok')
        }catch(e){
          setTimeout(function(){
            self.onPopupView600klive()
          },200)
        }
      },


      onPopupView60klive(){
        const self = this;
        try{
          const app = self.$f7;
          var order = app.form.convertToData('#FormBuffview60klive');
          order.amount = 1000;
          for (var i = 0; i < self.userbalance.balances.length; i++) {
            if(self.userbalance.balances[i].service_id.id == 60){
              order.service_id = self.userbalance.balances[i].service_id._id;
              break;
            }
          }
          app.form.fillFromData('#FormBuffview60klive', order);
          self.getDataForm('#FormBuffview60klive');
          self.getPromotionsApply(order.service_id);
          
          // alert('ok')
        }catch(e){
          setTimeout(function(){
            self.onPopupView60klive()
          },200)
        }
      },
      getPromotionsApply(service_id){
        const self = this;
        self.promotions = [];
        self.$root.loadMorePromotions(function(promotions){
          for (var i = 0; i < promotions.length; i++) {
            if(promotions[i].service_ids.split(',').indexOf(service_id) > -1){
              self.promotions.push(promotions[i]);
            }
          }
          // console.log(self.promotions)
        });
      },
      applyPromotion(el, code){
        const self = this;
        const app = self.$f7;
        var order = app.form.convertToData(el);
        order.promotion_code = code;
        app.form.fillFromData(el, order);
        self.getDataForm(el);
        self.changePromotion(el);
      },
      signin() {
        const self = this;
        const app = self.$f7;
        self.$f7.preloader.show();
        self.requestLogin(self.username, self.password, function(status, user){
          if(status){
            if(user && user.username == self.username){
              if(user.token && user.token.trim().length > 10){
                app.data.notifications = [];
                app.data.payments = [];
                app.data.videovieworders = [];
                app.data.userbalance = [];
                self.user = user;
                app.data.user = self.user;

                self.$root.setupSocket();
                app.form.storeFormData('user', self.user);
                // self.getBalance();
                setTimeout(() => {
                  //this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password);
                    self.$f7.preloader.hide();
                    self.userReload();
                }, 100);
              }else{
                setTimeout(() => {
                  self.$f7.dialog.alert(self.$root.setLang('please_create_token_api_for_use_this_app'));
                  self.$f7.preloader.hide();
                }, 100);
              }
            }else{
              setTimeout(() => {
                self.$f7.dialog.alert(self.$root.setLang('failed_login_try_again'));
                self.$f7.preloader.hide();
              }, 100);
            }
          }else{
            setTimeout(() => {
              self.$f7.dialog.alert(self.$root.setLang(user));
              self.$f7.preloader.hide();
            }, 100);
          }
        });
        
      },
      signup() {
        const self = this;
        const app = self.$f7;
        self.$f7.preloader.show();
        if(self.username == "" && self.password == ""){
          self.$f7.dialog.alert(self.$root.setLang('enter_username_and_password_for_sign_up'));
          self.$f7.preloader.hide();
        }else if(self.username == ""){
          self.$f7.dialog.alert(self.$root.setLang('enter_username_for_sign_up'));
          self.$f7.preloader.hide();
        }else if(self.password == ""){
          self.$f7.dialog.alert(self.$root.setLang('enter_password_for_sign_up'));
          self.$f7.preloader.hide();
        }else if(self.password.length < 6){
          self.$f7.dialog.alert(self.$root.setLang('password_must_be_least_6_characters'));
          self.$f7.preloader.hide();
        }else if(self.password != self.repassword){
          self.$f7.dialog.alert(self.$root.setLang('password_not_match'));
          self.$f7.preloader.hide();
        }else if(self.email == ""){
          self.$f7.dialog.alert(self.$root.setLang('enter_email_for_sign_up'));
          self.$f7.preloader.hide();
        }else{
          self.requestSignup(self.username, self.password, self.email, function(status, user){
            if(status){
              if(user && user.token && user.token.trim().length > 10){
                self.user = user;
                app.data.user = self.user;
                app.form.storeFormData('user', self.user);
                // self.getBalance();
                setTimeout(() => {
                  //this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password);
                    self.$f7.preloader.hide();
                    self.userReload();
                }, 100);
              }else{
                setTimeout(() => {
                  self.$f7.dialog.alert(self.$root.setLang('please_create_token_api_for_use_this_app'));
                  self.$f7.preloader.hide();
                }, 100);
              }
            }else{
              console.log(user)
              setTimeout(() => {
                // self.$f7.dialog.alert(self.$root.setLang('failed_sign_up_try_again'));
                self.$f7.dialog.alert(self.$root.setLang(user));
                self.$f7.preloader.hide();
              }, 100);
            }
          });
        }
      },

      forgot() {
        const self = this;
        const app = self.$f7;
        self.$f7.preloader.show();
        self.requestSignup(self.username, self.password, self.email, function(status, user){
          if(status){
            if(user && user.token && user.token.trim().length > 10){
              self.user = user;
              app.data.user = self.user;
              app.form.storeFormData('user', self.user);
              // self.getBalance();
              setTimeout(() => {
                //this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password);
                  self.$f7.preloader.hide();
                  self.userReload();
              }, 100);
            }else{
              setTimeout(() => {
                self.$f7.dialog.alert('Please generate API token to use this Application!');
                self.$f7.preloader.hide();
              }, 100);
            }
          }else{
            setTimeout(() => {
              self.$f7.dialog.alert('Login failed. Try again!');
              self.$f7.preloader.hide();
            }, 100);
          }
        });
        
      },
      requestLogin(username, password, callback){
        const self = this;
        const app = self.$f7;
        app.request.post(self.$root.$f7.params.apiurl, {action: 'login', username: username, password: password, supplier: self.$root.supplier}, function(user){
          if(user && user._id){
            callback(true, user);
          }else{
            callback(false, user.error);
          }
          
        },
        function(err){
          // console.log('err',err)
          // callback(false, err);
          try{
            var data = JSON.parse(err.response);
            callback(false, data.error);
          }catch(e){
            callback(false, 'failed_login_try_again');
          }
        },'json');
      },
      requestSignup(username, password, email, callback){
        const self = this;
        const app = self.$f7;
        app.request.post(self.$root.$f7.params.apiurl, {action: 'signup', username: username, password: password, email: email, supplier: self.$root.supplier}, function(user){
          if(user && user._id){
            callback(true, user);
          }else{
            callback(false, user.error);
          }
          
        },
        function(err){
          try{
            var data = JSON.parse(err.response);
            callback(false, data.error);
          }catch(e){
            callback(false, 'failed_sign_up_try_again');
          }
        },'json');
      },
      userReload(){
        const self = this;
        const app = self.$f7;
        if(app.data.user && app.data.user.token){
          self.getBalance()
          setTimeout(self.getViewersAvailable, 4000);
          app.request.post(self.$root.$f7.params.apiurl, {action: 'userreload', supplier: self.$root.supplier, token: app.data.user.token}, function(dataUser){
            if(dataUser && app.data.user && app.data.user.token){
              if(dataUser.user && dataUser.user._id && dataUser.user.token == app.data.user.token){
                self.user = dataUser.user;
                app.data.user = self.user;
                self.userStatic = dataUser.userStatic;
                app.data.userStatic = self.userStatic;
                app.form.storeFormData('user', self.user);
                app.form.storeFormData('userStatic', self.userStatic);
                setTimeout(self.userReload, 20000)
              }else if(dataUser.user && dataUser.user.logout && dataUser.user.logout == "yes"){
                self.signout();
              }else{
                setTimeout(self.userReload, 20000)
              }
            }else{
              setTimeout(self.userReload, 20000)
            }
          },
          function(err){
            // console.log('err',err)
            try{
              var data = JSON.parse(err.response);
              if(data && data.logout && data.logout == "yes"){
                self.signout();
              }else{
                setTimeout(self.userReload, 20000)
              }
            }catch(e){
              setTimeout(self.userReload, 20000)
            }
            
          },'json');
        }
      },
      signout() {
        const self = this;
        const app = self.$f7;
        app.form.removeFormData('user');
        self.user = null;
        self.userStatic = {};
        app.data.user = self.user ;
        app.data.userStatic = self.userStatic ;
        self.$root.socket_running = false;
        self.$root.socket.emit('end');
        // this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password);
      },
      newPageOpen() {
        const self = this;
        const app = self.$f7;
        self.actionNew = app.actions.create({
          buttons: [
            [
              {
                text: self.$root.setLang('video_uploaded_or_live_end'),
                label: true
              },
              // {
              //   text: 'Tạo đơn view lẻ',
              //   onClick: function () {
              //     self.getBalance();
              //     self.actionNew.close();
              //     self.popupBuffview = true;
              //     self.promotion_des = "";
              //     self.getDataForm('#FormBuffview')
              //   }
              // },
              // {
              //   text: 'Adbreaks 30k lượt xem 1 phút',
              //   onClick: function () {
              //     self.getBalance();
              //     self.actionNew.close();
              //     self.popupBuffview1m = true;
              //     self.promotion_des = "";
              //     self.getDataForm('#FormBuffview1m')
              //   }
              // },
              {
                text: self.$root.setLang('service_111'),
                onClick: function () {
                  self.getBalance();
                  self.actionNew.close();
                  self.popupBuffview600k2h = true;
                  self.promotion_des = "";
                  self.getDataForm('#FormBuffview600k2h')
                }
              }
              // ,
              // {
              //   text: 'Tạo đơn view theo ngày',
              //   onClick: function () {
              //     self.getBalance();
              //     self.actionNew.close();
              //     self.popupVipview = true;
              //     self.promotion_des = "";
              //     self.getDataForm('#FormVipview');
              //   }
              // }
            ],
            [
              {
                text: self.$root.setLang('video_livestreaming'),
                label: true
              },
              {
                text: self.$root.setLang('service_68'),
                onClick: function () {
                  self.getBalance();
                  self.actionNew.close();
                  self.popupBuffview600klive = true;
                  self.promotion_des = "";
                  self.getDataForm('#FormBuffview600klive')
                }
              },
              {
                text: self.$root.setLang('service_60'),
                onClick: function () {
                  self.getBalance();
                  self.actionNew.close();
                  self.popupBuffview60klive = true;
                  self.promotion_des = "";
                  self.getDataForm('#FormBuffview60klive')
                }
              },
              // {
              //   text: 'Tạo đơn mắt lẻ',
              //   onClick: function () {
              //     self.actionNew.close();
              //     self.popupBuffliveview = true;
              //     self.promotion_des = "";
              //     self.getDataForm('#FormBuffliveview')
              //   }
              // },
              // {
              //   text: 'Tạo đơn mắt theo ngày',
              //   onClick: function () {
              //     self.actionNew.close();
              //     self.popupVipliveview = true;
              //     self.promotion_des = "";
              //     self.getDataForm('#FormVipliveview');
              //   }
              // }
            ],
            [
              {
                text: self.$root.setLang('close'),
                color: 'red'
              }
            ]
          ],
        });
        self.actionNew.open();
      },
      //buff view begin
      submitFormBuffview(){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData('#FormBuffview');
        if(order.url.length < 5){
          app.dialog.alert('Vui lòng nhập đúng "URL" video', 'Thất bại');
        }else if(order.amount < 500){
          app.dialog.alert('Vui lòng nhập số lượng thấp nhất là 500', 'Thất bại');
        }else if(order.service_id.length < 10){
          app.dialog.alert('Bạn chưa chọn loại view', 'Thất bại');
        }else{
          app.dialog.progress('Đang tạo đơn...');
          app.request.post(self.$root.$f7.params.apiurl, {action: 'order', promotion: order.promotion_code, service_id: order.service_id,orders: [{url: order.url, amount: order.amount, note: order.note}], supplier: self.$root.supplier, token: app.data.user.token}, function(data){
            // console.log(data)
            setTimeout(() => {
              self.$f7.dialog.close();

              if(data && data.status == "success"){
                app.dialog.alert('Đơn hàng đặt thành công. Bạn có thể tiếp tục đặt đơn với liên kết mới', 'Thành công');
                order.url = "";
                app.form.fillFromData('#FormBuffview', order);
              }else{
                app.dialog.alert(data.message, 'Thất bại');
              }
            }, 500);
          },
          function(err){
            // console.log('err',err);
            app.dialog.alert(err);
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 500);
          },'json');
        }
      },
      //buff view end

      //buff view 1m begin
      submitFormBuffview1m(){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData('#FormBuffview1m');
        if(order.url.length < 5){
          app.dialog.alert('Vui lòng nhập đúng "URL" video', 'Thất bại');
        }else if(order.amount < 500){
          app.dialog.alert('Vui lòng nhập số lượng thấp nhất là 500', 'Thất bại');
        }else if(order.service_id.length < 10){
          app.dialog.alert('Bạn chưa chọn loại view', 'Thất bại');
        }else{
          app.dialog.progress('Đang tạo đơn...');
          app.request.post(self.$root.$f7.params.apiurl, {action: 'order', promotion: order.promotion_code, service_id: order.service_id,orders: [{url: order.url, amount: order.amount, note: order.note}], supplier: self.$root.supplier, token: app.data.user.token}, function(data){
            // console.log(data)
            setTimeout(() => {
              self.$f7.dialog.close();

              if(data && data.status == "success"){
                app.dialog.alert('Đơn hàng đặt thành công. Bạn có thể tiếp tục đặt đơn với liên kết mới', 'Thành công');
                order.url = "";
                app.form.fillFromData('#FormBuffview1m', order);
              }else{
                app.dialog.alert(data.message, 'Thất bại');
              }
            }, 500);
          },
          function(err){
            // console.log('err',err);
            app.dialog.alert(err);
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 500);
          },'json');
        }
      },

      //buff view 600k2h begin
      eventChangeAccessToken(el){
        // console.log(el)
      },
      submitFormBuffview600k2h(){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData('#FormBuffview600k2h');
        var accesstoken_check = false;
        if(order.accesstoken && (order.accesstoken.indexOf('EAAGNO') > -1 || order.accesstoken.indexOf('EAAGNO') > -1) && order.accesstoken.indexOf('"') == -1 && order.accesstoken.indexOf(',') == -1 && order.accesstoken.indexOf(' ') == -1 && order.accesstoken.length > 50 && order.accesstoken.length < 300){
         accesstoken_check = true;
        }
        if(!accesstoken_check){
          app.dialog.alert(self.$root.setLang('accesstoken_wrong'), self.$root.setLang('failed'));
        }else if(order.url.length < 5){
          app.dialog.alert(self.$root.setLang('url_wrong'), self.$root.setLang('failed'));
        }else if(order.amount != 5100){
          app.dialog.alert(self.$root.setLang('amount_wrong'), self.$root.setLang('failed'));
        }else if(order.service_id.length < 10){
          app.dialog.alert(self.$root.setLang('view_type_wrong'), self.$root.setLang('failed'));
        }else{
          app.dialog.progress(self.$root.setLang('ordering'));
          var orders = [];
          var urls = order.url.split('\n');
          for (var i = 0; i < urls.length; i++) {
            orders.push({url: urls[i].trim(), amount: order.amount, note: order.note, accesstoken: order.accesstoken})
          }
          app.request.post(self.$root.$f7.params.apiurl, {action: 'order', promotion: order.promotion_code, service_id: order.service_id,orders: orders, supplier: self.$root.supplier, token: app.data.user.token}, function(data){
            // console.log(data)
            setTimeout(() => {
              self.$f7.dialog.close();

              if(data && data.status == "success"){
                app.dialog.alert(self.$root.setLang('order_success_you_can_place_other'), self.$root.setLang('success'));
                order.url = "";
                order.accesstoken = "";
                order.note = "";
                app.form.fillFromData('#FormBuffview600k2h', order);
              }else{
                app.dialog.alert(data.message, self.$root.setLang('failed'));
              }
            }, 500);
          },
          function(err){
            // console.log('err',err);
            app.dialog.alert(err);
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 500);
          },'json');
        }
      },
      //buff view 600k2h end

      //buff view 600klive begin
      submitFormBuffview600klive(){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData('#FormBuffview600klive');
        
        var accesstoken_check = false;
        if(order.accesstoken && (order.accesstoken.indexOf('EAAGNO') > -1 || order.accesstoken.indexOf('EAAGNO') > -1) && order.accesstoken.indexOf('"') == -1 && order.accesstoken.indexOf(',') == -1 && order.accesstoken.indexOf(' ') == -1 && order.accesstoken.length > 50 && order.accesstoken.length < 300){
         accesstoken_check = true;
        }
        if(!accesstoken_check){
          app.dialog.alert(self.$root.setLang('accesstoken_wrong'), self.$root.setLang('failed'));
        }else if(order.url.length < 5){
          app.dialog.alert(self.$root.setLang('url_wrong'), self.$root.setLang('failed'));
        }else if(order.amount != 5100){
          app.dialog.alert(self.$root.setLang('amount_wrong'), self.$root.setLang('failed'));
        }else if(order.service_id.length < 10){
          app.dialog.alert(self.$root.setLang('view_type_wrong'), self.$root.setLang('failed'));
        }else{
          app.dialog.progress(self.$root.setLang('ordering'));
          var orders = [];
          var urls = order.url.split('\n');
          for (var i = 0; i < urls.length; i++) {
            orders.push({url: urls[i].trim(), amount: order.amount, note: order.note, accesstoken: order.accesstoken})
          }
          app.request.post(self.$root.$f7.params.apiurl, {action: 'order', promotion: order.promotion_code, service_id: order.service_id,orders: orders, supplier: self.$root.supplier, token: app.data.user.token}, function(data){
            // console.log(data)
            setTimeout(() => {
              self.$f7.dialog.close();

              if(data && data.status == "success"){
                app.dialog.alert(self.$root.setLang('order_success_you_can_place_other'), self.$root.setLang('success'));
                order.url = "";
                order.accesstoken = "";
                order.note = "";
                app.form.fillFromData('#FormBuffview600klive', order);
              }else{
                app.dialog.alert(data.message, self.$root.setLang('failed'));
              }
            }, 500);
          },
          function(err){
            // console.log('err',err);
            app.dialog.alert(err);
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 500);
          },'json');
        }
      },
      //buff view 600klive end

      //buff view 60klive begin
      submitFormBuffview60klive(){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData('#FormBuffview60klive');
        
        var accesstoken_check = false;
        if(order.accesstoken && (order.accesstoken.indexOf('EAAGNO') > -1 || order.accesstoken.indexOf('EAAGNO') > -1) && order.accesstoken.indexOf('"') == -1 && order.accesstoken.indexOf(',') == -1 && order.accesstoken.indexOf(' ') == -1 && order.accesstoken.length > 50 && order.accesstoken.length < 300){
         accesstoken_check = true;
        }
        if(!accesstoken_check){
          app.dialog.alert(self.$root.setLang('accesstoken_wrong'), self.$root.setLang('failed'));
        }else if(order.url.length < 5){
          app.dialog.alert(self.$root.setLang('url_wrong'), self.$root.setLang('failed'));
        }else if(order.amount != 1000){
          app.dialog.alert(self.$root.setLang('amount_wrong'), self.$root.setLang('failed'));
        }else if(order.service_id.length < 10){
          app.dialog.alert(self.$root.setLang('view_type_wrong'), self.$root.setLang('failed'));
        }else{
          app.dialog.progress(self.$root.setLang('ordering'));
          var orders = [];
          var urls = order.url.split('\n');
          for (var i = 0; i < urls.length; i++) {
            orders.push({url: urls[i].trim(), amount: order.amount, note: order.note, accesstoken: order.accesstoken})
          }
          app.request.post(self.$root.$f7.params.apiurl, {action: 'order', promotion: order.promotion_code, service_id: order.service_id,orders: orders, supplier: self.$root.supplier, token: app.data.user.token}, function(data){
            // console.log(data)
            setTimeout(() => {
              self.$f7.dialog.close();

              if(data && data.status == "success"){
                app.dialog.alert(self.$root.setLang('order_success_you_can_place_other'), self.$root.setLang('success'));
                order.url = "";
                order.accesstoken = "";
                order.note = "";
                app.form.fillFromData('#FormBuffview60klive', order);
              }else{
                app.dialog.alert(data.message, self.$root.setLang('failed'));
              }
            }, 500);
          },
          function(err){
            // console.log('err',err);
            app.dialog.alert(err);
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 500);
          },'json');
        }
      },
      //buff view 60klive end
      // vip view begin
      submitFormVipview(){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData('#FormVipview');
        if(order.url.length < 5){
          app.dialog.alert('Vui lòng nhập đúng "URL" video', 'Thất bại');
        }else if(order.amount < 500){
          app.dialog.alert('Vui lòng nhập số lượng thấp nhất là 500', 'Thất bại');
        }else if(order.num_days < 5 || order.num_days > 365){
          app.dialog.alert('Vui lòng nhập số ngày từ 5 - 365', 'Thất bại');
        }else if(order.service_id.length < 10){
          app.dialog.alert('Bạn chưa chọn loại view', 'Thất bại');
        }else{
          app.dialog.progress('Đang tạo đơn...');
          app.request.post(self.$root.$f7.params.apiurl, {action: 'order', promotion: order.promotion_code, service_id: order.service_id,orders: [{url: order.url, amount: order.amount, num_minutes: order.num_days, note: order.note}], supplier: self.$root.supplier, token: app.data.user.token}, function(data){
            // console.log(data)
            setTimeout(() => {
              self.$f7.dialog.close();

              if(data && data.status == "success"){
                app.dialog.alert('Đơn hàng đặt thành công. Bạn có thể tiếp tục đặt đơn với liên kết mới', 'Thành công');
                order.url = "";
                app.form.fillFromData('#FormVipview', order);
              }else{
                app.dialog.alert(data.message, 'Thất bại');
              }
            }, 500);
          },
          function(err){
            // console.log('err',err);
            app.dialog.alert(err);
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 500);
          },'json');
        }
      },
      // vip view end
      // vip liveview begin
      submitFormVipliveview(){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData('#FormVipliveview');
        if(order.url.length < 5){
          app.dialog.alert('Vui lòng nhập đúng "URL" video', 'Thất bại');
        }else if(order.amount < 500){
          app.dialog.alert('Vui lòng nhập số lượng thấp nhất là 500', 'Thất bại');
        }else if(order.num_days < 7 || order.num_days > 365){
          app.dialog.alert('Vui lòng nhập số ngày từ 7 - 365', 'Thất bại');
        }else if(order.service_id.length < 10){
          // app.dialog.alert('It seem like this service not active with you. Please contact with Administrator for active service.', 'Failed');
          app.dialog.alert('Dịch vụ này chưa kích hoạt cho bạn. Vui lòng liên hệ với Quản trị viên để kích hoạt dịch vụ.', 'Thất bại');
        }else{
          app.dialog.progress('Đang tạo đơn...');
          app.request.post(self.$root.$f7.params.apiurl, {action: 'order', promotion: order.promotion_code, service_id: order.service_id,orders: [{url: order.url, amount: order.amount, num_minutes: order.num_days, note: order.note}], supplier: self.$root.supplier, token: app.data.user.token}, function(data){
            // console.log(data)
            setTimeout(() => {
              self.$f7.dialog.close();

              if(data && data.status == "success"){
                app.dialog.alert('Đơn hàng đặt thành công. Bạn có thể tiếp tục đặt đơn với liên kết mới', 'Thành công');
                order.url = "";
                app.form.fillFromData('#FormVipliveview', order);
              }else{
                app.dialog.alert(data.message, 'Thất bại');
              }
            }, 500);
          },
          function(err){
            // console.log('err',err);
            app.dialog.alert(err);
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 500);
          },'json');
        }
      },
      // vip liveview end

      //buff live begin
      submitFormBuffliveview(){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData('#FormBuffliveview');
        if(order.url.length < 5){
          app.dialog.alert(self.$root.setLang('url_wrong'), self.$root.setLang('failed'));
        }else if(order.amount < 10){
          app.dialog.alert(self.$root.setLang('live_view_amount_min'), self.$root.setLang('failed'));
        }else if(order.num_minutes < 30){
          app.dialog.alert(self.$root.setLang('live_view_minute_min'), self.$root.setLang('failed'));
        }else if(order.service_id.length < 10){
          app.dialog.alert(self.$root.setLang('this_service_not_active'), self.$root.setLang('failed'));
        }else{
          app.dialog.progress(self.$root.setLang('ordering'));
          app.request.post(self.$root.$f7.params.apiurl, {action: 'order', is_vip: "yes", service_id: order.service_id,orders: [{url: order.url, amount: order.amount, num_minutes: order.num_minutes, note: order.note}], supplier: self.$root.supplier, token: app.data.user.token}, function(data){
            // console.log(data)
            setTimeout(() => {
              self.$f7.dialog.close();

              if(data && data.status == "success"){
                app.dialog.alert(self.$root.setLang('order_success_you_can_place_other'), self.$root.setLang('success'));
                order.url = "";
                app.form.fillFromData('#FormBuffliveview', order);
              }else{
                app.dialog.alert(data.message, self.$root.setLang('failed'));
              }
            }, 500);
          },
          function(err){
            // console.log('err',err);
            app.dialog.alert(err);
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 500);
          },'json');
        }
      },
      // buff live end

      getDataForm(el){
        var self = this;
        var app = self.$f7;
        if(app && app.form){
          var formData = app.form.convertToData(el);
          if(formData && formData.amount && formData.service_id){
            self.getPriceService(formData);
          }else{
            self.order_price = self.$root.setPrice(0);
            self.order_price_root = self.$root.setPrice(0);
          }
        }else{
          self.order_price = self.$root.setPrice(0);
            self.order_price_root = self.$root.setPrice(0);
        }
      },
      getPriceService(order){
        var self = this;
        var app = self.$f7;
        var service_selected = {};
        var userservice_selected = {};
        var price = 0;
        for (var i = 0; i < self.userbalance.balances.length; i++) {
          if(self.userbalance.balances[i].service_id._id.toString() == order.service_id){
            service_selected = self.userbalance.balances[i];
          }
        }
        for (var i = 0; i < self.userbalance.userservices.length; i++) {
          if(self.userbalance.userservices[i].service_id == order.service_id){
            userservice_selected = self.userbalance.userservices[i];
          }
        }
        var urls = [];
        var urls_order = order.url.split('\n');
        for (var i = 0; i < urls_order.length; i++) {
          if(urls_order[i].trim() != ""){
            urls.push(urls_order[i]);
          }
        }
        // console.log(urls)
        order.url = urls.join('\n');
        if(service_selected && service_selected.service_id && service_selected.service_id.service_group == order.service_type){
          price = ((urls.length == 0)? 1: urls.length) *order.amount * userservice_selected.price;
        }
        if(order.service_type == "videoview"){
          order.price = price;
        }else if(order.service_type == "autoorder_view"){
          order.price = price*order.num_days;
        }else if(order.service_type == "liveview"){
          order.price = price*order.num_minutes / 30;
        }else if(order.service_type == "autoorder_live"){
          order.price = price*order.num_days;//num_minutes
        }else{
          order.price = price;
        }
        
        self.priceforPromotion(order, service_selected);
        // return self.$root.setPrice(price);
      },
      checkServiceActive(service_id){
        var self = this;
        var checked = false;
        if(self.userbalance){
          for (var i = 0; i < self.userbalance.userservices.length; i++) {
            if(self.userbalance.userservices[i].service_id == service_id && self.userbalance.userservices[i].status == 1){
              checked = true;
              break;
            } 
          }
        }
        return checked;
      },
      priceforPromotion(order, service_selected){
        var self = this;
        var app = self.$f7;
        var text_return = ''
        var price_take_confirm = order.price;
        order.price_root = order.price;
        try{
          order.promotion = JSON.parse(order.promotion)
        }catch(e){}
        var check_promotion_available = false;
        if(order.promotion && order.promotion._id){
          var time_now = Date.now();
          check_promotion_available = false;
          if(time_now < order.promotion.start_date){
            check_promotion_available = false;
            text_return = self.$root.setLang('this_promotion_still_not_begin')
          }else{
            check_promotion_available = true;
          }
          if(check_promotion_available){
            if(time_now > order.promotion.expired_date){
              check_promotion_available = false;
              text_return = self.$root.setLang('this_promotion_had_been_expired')
            }else{
              check_promotion_available = true;
            }
          }

          if(check_promotion_available){
            if(order.promotion.service_ids == '*'){
              check_promotion_available = true;
            }else{
              var service_ids = order.promotion.service_ids.split(',');
              if(service_ids.indexOf(order.service_id) > -1){
                check_promotion_available = true;
              }else{
                check_promotion_available = false;
                text_return = self.$root.setLang('this_promotion_not_support_service')+' '+self.$root.getNameService(service_selected.service_id)
              }
            }
          }
          if(check_promotion_available){
            if(order.promotion.user_ids == '*'){
            }else{
              check_promotion_available = false;
              var user_ids = order.promotion.user_ids.split(',');
              if(user_ids.indexOf(self.user._id.toString()) > -1){
                check_promotion_available = true;
              }else{
                check_promotion_available = false;
                text_return = self.$root.setLang('this_promotion_not_for_you')
              }
            }
          }else{
          }
          if(check_promotion_available){
            if(order.promotion.condition_min_price && order.promotion.condition_min_price > 0){
              if(order.price_root < order.promotion.condition_min_price){
                check_promotion_available = false;
                text_return = self.$root.setLang('this_promotion_required_min')+' '+self.$root.setPrice(order.promotion.condition_min_price)
              }
            }
          }else{
          }
          if(check_promotion_available){
            var price_reduce = 0;
            if(order.promotion && order.promotion._id){
              if(order.promotion.is_percent == 1){
                price_reduce = price_take_confirm*order.promotion.value/100;
                text_return = self.$root.setLang('reduce')+' '+order.promotion.value+'%'+'. '+self.$root.setLang('note') +': '+ self.$root.setLang('reduce_note');
              }else{
                price_reduce = order.promotion.value;
                text_return = self.$root.setLang('reduce')+' '+self.$root.setPrice(order.promotion.value)+'. '+self.$root.setLang('note') +': '+ self.$root.setLang('reduce_note');
              } 
              if(order.promotion.max_price && price_reduce > order.promotion.max_price){
                price_reduce = order.promotion.max_price;
                text_return = self.$root.setLang('reduce')+' '+self.$root.setPrice(order.promotion.max_price)+'. '+self.$root.setLang('note') +': '+ self.$root.setLang('reduce_note');
              }
            }
            price_take_confirm -= price_reduce;
            if(price_take_confirm < 0){
              price_take_confirm = 0;
            }
            
          }else{
          }
        }else{
          if(order.promotion_code){
            text_return = self.$root.setLang('this_promotion_not_found')
          }
        }
        order.price = price_take_confirm;
        self.promotion_des = text_return;
        self.order_price_root = self.$root.setPrice(order.price_root );
        self.order_price = self.$root.setPrice(order.price);
      },
      changeUrl(el){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData(el);
        self.getDataForm(el)
      },
      changePromotion(el){
        var self = this;
        var app = self.$f7;
        var order = app.form.convertToData(el);
        clearTimeout(self.searchpromotionTimeout);
        var getPrice = function(){
          if(order.service_type == "videoview"){
            if(typeof order.is_view1m !== "undefined" && order.is_view1m){
              self.getDataForm('#FormBuffview1m')
            }else if(typeof order.is_view600k2h !== "undefined" && order.is_view600k2h){
              self.getDataForm('#FormBuffview600k2h')
            }else if(typeof order.is_view600klive !== "undefined" && order.is_view600klive){
              self.getDataForm('#FormBuffview600klive')
            }else if(typeof order.is_view60klive !== "undefined" && order.is_view60klive){
              self.getDataForm('#FormBuffview60klive')
            }else{
              console.log("here",order)
              self.getDataForm('#FormBuffview')
            }
          }else if(order.service_type == "autoorder_view"){
            self.getDataForm('#FormVipview')
          }else if(order.service_type == "liveview"){
            self.getDataForm('#FormBuffliveview')
          }else if(order.service_type == "autoorder_live"){
            self.getDataForm('#FormVipliveview')
          }
        }
        self.promotion_des = "";
        if(order && order.promotion_code){
          order.promotion_code = order.promotion_code.toUpperCase().replace(/ /g,'_').replace(/[^a-zA-Z0-9_]/gi,'');
          app.form.fillFromData(el, order);
          self.searchpromotionTimeout = setTimeout(function(){
            self.searchpromotion(order.promotion_code, function(promotion){
              if(promotion){
                order.promotion = JSON.stringify(promotion);
                app.form.fillFromData(el, order);
                getPrice()
              }else{
                order.promotion = "";
                app.form.fillFromData(el, order);
                getPrice()
              }
            })
          }, 500)
        }else{
          order.promotion = "";
          app.form.fillFromData(el, order);
          getPrice()
        }

      },
      searchpromotion(promotion_code, callback){
        var self = this;
        var app = self.$f7;
          app.request.post(self.$root.$f7.params.apiurl, {action: 'searchpromotion', promotion_code: promotion_code, supplier: self.$root.supplier, token: app.data.user.token}, function(promotion){
            callback(promotion)
          },
          function(err){
            // console.log('err',err)
            callback(false);
          },'json');
      },
      getNotifications() {
        self.$f7.data.notifications = [];
      },
      getNotificationsold() {
        var self = this;
        if(self.$f7.data.notifications.length > 0){
        }else{
          var accept_show_loading = true;
          setTimeout(() => {
            if(accept_show_loading){
              self.$f7.dialog.preloader(self.$root.setLang('loading'));
            }
          }, 100);
          self.$f7.data.notifications = [];
          var app = self.$f7;
          // request user data on page init
          app.request.post(self.$root.$f7.params.apiurl, {action: 'notificationslist', supplier: self.$root.supplier, token: app.data.user.token, service_ids: self.$root.services.join(','), page: 1}, function(data){
            var notifications = data.data;
            accept_show_loading = false;
            
            // setTimeout(() => {
              self.$f7.data.notifications = self.$f7.data.notifications.concat(notifications);
              self.$f7.dialog.close();
            // }, 500);
          },
          function(err){
            // console.log('err',err)
            accept_show_loading = false;
            self.$f7.dialog.close();
            setTimeout(self.getNotifications, 5000)
          },'json');
        }
      },

      getPayments() {
        var self = this;
        if(self.$f7.data.payments && self.$f7.data.payments.length > 0){
        }else{
          var accept_show_loading = true;
          setTimeout(() => {
            if(accept_show_loading){
              self.$f7.dialog.preloader(self.$root.setLang('loading'));
            }
          }, 100);
          self.$f7.data.payments = [];
          var app = self.$f7;
          // request user data on page init
          app.request.post(self.$root.$f7.params.apiurl, {action: 'paymentslist', supplier: self.$root.supplier, token: app.data.user.token, service_ids: self.$root.services.join(','), page: 1}, function(data){
            var payments = data.data;
            accept_show_loading = false;
            
            // setTimeout(() => {
              self.$f7.data.payments = self.$f7.data.payments.concat(payments);
              self.$f7.dialog.close();
            // }, 500);
          },
          function(err){
            // console.log('err',err)
            accept_show_loading = false;
            self.$f7.dialog.close();
            setTimeout(self.getPayments, 5000)
          },'json');
        }
      },
      getVideovieworders(numberGet) {
        var self = this;
        if(self.$f7.data.videovieworders.length > 0){

        }else{
          var accept_show_loading = true;
          setTimeout(() => {
            if(accept_show_loading){
              self.$f7.dialog.preloader(self.$root.setLang('loading'));
            }
          }, 100);
          self.$f7.data.videovieworders = [];
          var app = self.$f7;
          // request user data on page init
          app.request.post(self.$root.$f7.params.apiurl, {action: 'livevieworderslist', is_vip: "yes", supplier: self.$root.supplier, token: app.data.user.token, service_ids: self.$root.services.join(','), page: 1}, function(data){
            // console.log('data', data)
            var videovieworders = data.data;
            accept_show_loading = false;
            if(videovieworders && videovieworders.length > 0){
              self.$f7.data.videovieworders = self.$f7.data.videovieworders.concat(videovieworders);
              self.$f7.dialog.close();
            }else{
              self.$f7.dialog.close();
            }
            // setTimeout(() => {
              
            // }, 500);
          },
          function(err){
            // console.log('err',err, numberGet)
            accept_show_loading = false;
            self.$f7.dialog.close();
            numberGet++;
            if(numberGet > 20){

            }else{
              setTimeout(function(){
                self.getVideovieworders(numberGet)
              }, 5000)
            }
          },'json');
        }
      },
      getBalance() {
        var self = this;
          var app = self.$f7;
          app.request.post(self.$root.$f7.params.apiurl, {action: 'getbalance', supplier: self.$root.supplier, token: app.data.user.token}, function(userbalance, status, xhr){
            // console.log('xhr',xhr.headers)
            app.data.userbalance = userbalance;
            self.userbalance = userbalance;
            self.$root.setupSocket();
          },
          function(err){
            // console.log('err',err)
            // setTimeout(function(){
            //   self.getBalance();
            // }, 20000)
          },'json');
      },

      getViewersAvailable() {
        var self = this;
          var app = self.$f7;
          app.request.post(self.$root.$f7.params.apiurl, {action: 'viewersavailable', is_vip: "yes", supplier: self.$root.supplier, token: app.data.user.token}, function(viewersavailable){
            if(viewersavailable && viewersavailable.viewers){
              self.$root.viewersavailable = viewersavailable.viewers;
              self.$root.viewersavailable_admin = viewersavailable.viewers;
            }
          },
          function(err){
            // console.log('err',err)
            // setTimeout(function(){
            //   self.getViewersAvailable();
            // }, 25000)
          },'json');
      },
      // setLanguage(this_state,title){
      //   try{
      //       if(typeof self.$f7.data.language[this_state] !== 'undefined'){
      //           return self.$f7.data.language[this_state];
      //       }
      //   }catch(e){
      //   }
      //   return title;
      // },
      onPageInt() {
        const self = this;
      },
    },
    mounted() {
      var self = this;
      // console.log(self)
      var app = self.$f7;
      // setInterval(function(){
      //   console.log(app.views.current.name)
      // }, 2000)
      self.initnum_minutes();
      var lang = app.data.lang;
      try{
        var store_lang = app.form.getFormData('lang');
        if(store_lang && store_lang != ""){
          lang = store_lang;
          self.$root.changeLang(lang)
        }else{
          self.$root.changeLang(self.$root.lang)
        }
      }catch(e){
        self.$root.changeLang(self.$root.lang)
      }

      try{
        var store_darkTheme = app.form.getFormData('darkTheme');
        // console.log(store_darkTheme,self.$root.darkTheme)
        if(store_darkTheme){
          self.$root.darkTheme = store_darkTheme;
          self.$root.setColorTheme();
        }
      }catch(e){}
      var user = app.form.getFormData('user');
      var userStatic = app.form.getFormData('userStatic');
      if(user){
        self.user = user;
        app.data.user = self.user;
        if(userStatic){
          self.userStatic = userStatic;
          app.data.userStatic = self.userStatic;
        }
        // self.getBalance();
        self.userReload();
      }
      //   app.on('pageInit', function (page) {
      //     console.log('hihi',page.name)
      //   });
      // this.$f7ready((f7) => {
      //   console.log('Call F7 APIs here')
      //   // Call F7 APIs here
      // });
    },
    computed: {
      myaccesstoken: {
        get() {
          return this.field;
        },
        set(newValue) {
          // let a = newValue === "" ? 0 : newValue;
          var self = this;
          var app = self.$f7;
          newValue.target.value = self.$root.getAccesstokenFromHtml(newValue.target.value);
          if(newValue.target.value.indexOf('EAAGNO') > -1 || newValue.target.value.indexOf('EAAGNO') > -1){
            newValue.target.style.height = '117px';
          }
          // this.$emit("update:field", (newValue.target.value));
        },
      },
    },
  }
</script>