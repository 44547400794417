var lang = {
	// page addfund begin",
	back: "Trở về",
	add_fund: "Nạp tiền",
	balance_payment_method_automatic: "Tự động cập nhật khi nhận thanh toán",
	balance_payment_method_automatic_des: "Hệ thống của chúng tôi hỗ trợ tự động cập nhật thanh toán qua các kênh: Binance, Skrill, Perfect Money, VietComBank và một số kênh nạp thủ công: USDT TRC20, Ethereum, Bitcoin. Vui lòng chỉ nhập đúng mã Code hiển thị màu đỏ bên dưới cũng như trong hình minh hoạ, bạn sẽ được cập nhật ngay tức thì khi tài khoản của chúng tôi nhận được thanh toán.",
	payment_addfund_choose_the_wallet_you_want_to_top_up: "Chọn ví mà bạn muốn nạp tiền",
	payment_method: "Phương thức",
	payment_note: "Nội dung/Ghi chú/Mô tả/Tin nhắn/Mục đích",
	payment_addfund_enter_the_note_as_image_below_view_type:"Nhập ghi chú như hình bên dưới",
	wallet: "Ví",

	// page order begin",
	orders: "Đơn hàng",
	orders_help_action: "Giữ và trượt đơn từ Phải sang trái để thực hiện các hành động đối với đơn",
	key_word: "Từ khoá",
	key_word_placeholder: "Nhập từ khoá, id đơn, url, video id hoặc profile id",
	status: "Trạng thái",
	all: "Tất cả",
	status_1: "Đợi kích hoạt",
	status_2: "Đã kích hoạt",
	status_3: "Đang chạy",
	status_4: "Đã hoàn thành",
	status_5: "Đã huỷ",
	status_7: "Đang yêu cầu hủy",
	status_8: "Đang tạm dừng",
	copy_id: "Sao chép ID",
	delete: "Xoá",
	ordered: "đặt",
	success: "Thành công",
	request_had_been_success: 'Yêu cầu của bạn đã gửi thành công, bạn sẽ sớm nhận được phản hồi',
	request_had_been_failed: 'Yêu cầu bị từ chối',
	request_sending: 'Đang gửi yêu cầu...',
	change_accesstoken: "Thay đổi Access token",
	change_accesstoken_guide: 'Vào trang Creator Studio, xem mã nguồn của page đó(Ctrl+U) và tìm từ khoá "EAAciL", sao chép toàn bộ mã token đó vào đây',
	cancel: "Huỷ",
	send: "Gửi",
	failed: "Thất bại",
	accesstoken_updated: "Access token đã được cập nhật",
	accesstoken_updating: "Đang thay đổi Access token...",
	accesstoken_wrong: "Token bạn nhập không đúng, hãy kiểm tra lại.",
	select_action_for_order: "Lựa chọn hành động bạn muốn làm",
	copy_url: "Sao chép URL",
	copy_id_video: "Sao chép Id Video",
	can_not_pause_this_order: "Không thể tạm dừng đơn hàng",
	can_not_change_this_order: "Không thể thay đổi đơn hàng này",
	pause: "Tạm dừng",
	can_not_pause_this_order: "Không thể tạm dừng đơn hàng này",
	order_not_active: "Đơn hàng chưa kích hoạt",
	resume: "Chạy tiếp",
	request_cancel: "Yêu cầu huỷ",
	can_not_request_cancel_this_order: "Không thể yêu cầu huỷ đơn hàng này",
	required: "Bắt buộc",
	service_111:"600k phút",
	service_68:"600k phút live",
	service_60:"60k phút live",

	// page payments begin",
	payments_history: "Lịch sử giao dịch",
	your_payments: "Tất cả giao dịch của bạn",

	//page home
	intro_des: "Cảm ơn bạn đã tham gia Hệ sinh thái AmaC cùng với hơn 300 CTV, Đại lý trên toàn thế giới, chúng tôi chuyên nhà cung cấp các dịch vụ liên quan đến Adbreaks",
	intro: "Giới thiệu",
	orders_from_your_client: "Đơn của khách hàng",
	your_clients: "Khách hàng của bạn",
	your_orders: "Đơn của bạn",
	you_spent: "Bạn đã chi tiêu",
	statistic_today_all: "Thống kê Hôm nay / Tổng",
	manage_orders_here: "Quản lý khách hàng tại đây",
	discount: "Ưu đãi",
	price_list: "Bảng giá",
	balance: "Số dư",
	hi: "Xin chào",

	//page 404
	sorry: "Xin lỗi",
	your_link_access_not_found: "Liên kết bạn đang truy cập không tồn tại.",

	//page service
	service_list: "Danh sách dịch vụ",
	price_list_for_you: "Bảng giá dành cho bạn",

	//page promotion
	discount_code: "Mã giảm giá",
	expiry_date_until_end_of_day: "Hạn sử dụng đến hết ngày",
	click_the_line_to_copy_discount_code: "Nhấn vào dòng để sao chép mã ưu đãi",
	offer_for_you: "Ưu đãi dành cho bạn",
	promotions_description: 'Giảm {value} cho tất cả đơn hàng của "{service}" dịch vụ trong {pack_time} kể từ khi bạn mua khuyến mãi này.',
	promotions_hours: "giờ",
	promotions_day: "ngày",
	promotions_days: "ngày",
	promotions_amount_max: "Tối đa {view_remain} lượt xem.",
	promotions_description_package: "Tặng bạn khuyến mãi giảm {value} cho tất cả đơn hàng của tất cả dịch vụ.",
	promotions_description_package: 'Tặng bạn khuyến mãi giảm {value} cho tất cả đơn hàng của dịch vụ "{service}".',
	promotions_description_min_price: "Ít nhất {min_price} / đơn",
	promotions_amount_remain: "Còn lại {view_remain} lượt xem",
	promotions_all: "tất cả",
	promotions_free: "Miễn phí",

	//page contact
	contacts_contacts_info: "Thông tin liên hệ",
	contacts_email: "Email",
	contacts_phone_zalo_whatsapp_telegram: "Phone, Zalo, WhatsApp, Telegram",

	//page notifications
	notifications: "Thông báo",
	your_notifications: "Thông báo của bạn",

	//page setting
	settings: "Cài đặt",
	comming_soon: "Đang cập nhật...",

	//page app
	sign_out: "Đăng xuất",
	accounts: "Tài khoản",
	change_password: "Đổi mật khẩu",
	change_email: "Đổi email",
	change_avatar: "Đổi avatar",
	place_order: "Tạo đơn hàng",
	get_accesstoken: "Lấy access token Facebook",
	guide: "Hướng dẫn",
	username: "Tên đăng nhập",
	username_placeholder: "Tên đăng nhập của bạn",
	password: "Mật khẩu",
	password_placeholder: "Mật khẩu của bạn",
	sign_in: "Đăng nhập",
	sign_up: "Đăng ký",
	sign_in_help: "Bạn vẫn có thể sử dụng tài khoản ở site cũ để đăng nhập và sử dụng",
	home: "Trang chủ",
	new_order: "Đơn mới",
	transactions_history: "Lịch sử GD",
	contacts: "Liên hệ",
	close: "Đóng",
	title_600k: "Đơn 600k phút video từ 123 phút",
	des_600k: '<span style="color: #ff6b22; font-weight: bold;">Lưu ý</span>: Video công khai và Thời lượng phải trên 123 phút mới xử lý được',
	video_url: "Liên kết video",
	video_url_placeholder: "Mỗi link là mỗi dòng",
	accesstoken: "Access token",
	accesstoken_placeholder: "Bắt buộc phải nhập token vào đây để lấy dữ liệu số phút hoàn thành",
	note: "Ghi chú",
	note_placeholder: "Nhập vào ghi chú nếu có",
	discount_code: "Mã khuyến mãi",
	discount_code_placeholder: "Nhập vào mã khuyến mãi",
	you_will_pay: "Bạn sẽ trả",
	for_this_order: "cho đơn hàng này.",
	how_to_get_accesstoken: "Cách lấy Access token",
	get_accesstoken_guide: 'Vào trang "https://business.facebook.com/business_locations", xem mã nguồn của page đó(Ctrl+U) và sao chép toàn bộ nội dung đó(Ctrl+A rồi Ctrl+C), cuối cùng dán(Ctrl+V) vào mục Access token site sẽ tự động tìm và lấy đúng mã',
	title_600k_live: "Đơn 600k phút livestream",
	des_600k_live: '<span style="color: #ff6b22; font-weight: bold;">Lưu ý</span>: Livestream công khai và kéo dài trong 61 phút đến 8 giờ',
	title_60k_live: "Đơn 60k phút livestream",
	des_60k_live: '<span style="color: #ff6b22; font-weight: bold;">Lưu ý</span>: Livestream công khai và kéo dài trong 61 phút đến 8 giờ',
	support_urls: "Hỗ trợ tất cả các dạng link như",
	please_create_token_api_for_use_this_app: "Vui lòng tạo mã token API để sử dụng ứng dụng này!",
	failed_login_try_again: "Đăng nhập thất bại. Hãy thử lại!",
	enter_username_and_password_for_sign_up: "Hãy nhập tên và mật khẩu để đăng ký",
	enter_username_for_sign_up: "Hãy nhập vào Tên đăng nhập để đăng ký",
	enter_password_for_sign_up: "Hãy nhập Mật khẩu để đăng ký",
	failed_sign_up_try_again: "Đăng ký thất bại! Hãy thử lại",
	video_uploaded_or_live_end: "Video tải lên hoặc đã live",
	video_livestreaming: "Video đang phát trực tiếp",
	url_wrong: 'Vui lòng nhập đúng "URL" video',
	mount_wrong: "Vui lòng nhập đúng số lượng",
	view_type_wrong: "Bạn chưa chọn loại view",
	ordering: "Đang tạo đơn...",
	order_success_you_can_place_other: "Đơn hàng đặt thành công. Bạn có thể tiếp tục đặt đơn với liên kết mới",
	loading: "Đang tải...",
	this_promotion_still_not_begin: "Mã này chưa tới ngày để sử dụng",
	this_promotion_had_been_expired: "Mã này đã hết hạn",
	this_promotion_not_support_service: "Mã này không được cho dịch vụ bạn đang đặt",
	this_promotion_not_for_you: "Mã này không áp dụng cho bạn",
	this_promotion_required_min: "Mã này yêu cầu tối thiểu",
	reduce: "Giảm",
	this_promotion_not_found: "Mã này không tồn tại",
	accesstoken_can_not_detect: "Can not detect access token, you should get manual!",

	//file app.js
	copied: "Đã sao chép",
	error: "Lỗi",
	can_not_copy: "Không sao chép được",
	notifications_orders_created: 'Đơn hàng #{main_id} trong {service} bởi {userApply} vừa được đặt.',
	notifications_orders_restarted: 'Đơn hàng #{main_id} bởi {userApply} vừa chạy lại.',
	notifications_orders_cancelled: 'Đơn hàng #{main_id} bởi {userApply} đã bị huỷ.',
	notifications_orders_deleted: 'Đơn hàng #{main_id} bởi {userApply} đã bị xoá.',
	notifications_orders_completed: 'Đơn hàng #{main_id} bởi {userApply} đã hoàn thành.',
	notifications_orders_refundtimelive: 'Đơn hàng #{main_id} của {userApply} livestream chưa hết thời gian đã cài.',
	notifications_orders_renew: 'Đơn hàng #{main_id} trong {service} đã tự động tạo mới.',
	notifications_payments_payment: '{userApply} {price} trong ví {service}. {userApply} đã thanh toán cho đơn hàng #{main_id}.',
	notifications_payments_refund: '{userApply} {price} trong ví {service}. {userApply} đã nhận tiền hoàn lại từ đơn hàng #{main_id}.',
	notifications_payments_refundtimelive: '{userApply} {price}. {userApply} hoàn tiền cho đoen hàng #{main_id} livestream chưa hết thời gian đã cài.',
	notifications_payments_addfund: '{userApply} {price} trong ví {service}. {userApply} đã nạp tiền qua {payment_method}.',
	notifications_payments_receivefund: '{userApply} {price} trong ví {service}. {userApply} nhận tiền từ {user_more} qua {payment_method}.',
	notifications_payments_receive: '{userApply} {price} trong ví {service}. {user_more} đã xác nhận thanh toán qua {payment_method}.',
	notifications_payments_sent: '{userApply} {price} trong ví {service}. {userApply} đã gửi cho {user_more} qua {payment_method}.',
	notifications_payments_transfer_from: '{userApply} {price} trong ví {service}. {userApply} đã thanh toán cho yêu cầu chuyển tiền từ {service_old} qua {service_new}.',
	notifications_payments_transfer_to: '{userApply} {price} trong ví {service}. {userApply} đã nhận được thanh toán cho yêu cầu chuyển tiền từ  {service_old} qua {service_new}.',
	notifications_payments_transfer_pending: '{user_more} vừa tạo yêu cầu chuyển tiền từ {service_old} qua {service_new}. {userApply} cần xác nhận yêu cầu này cho {user_more} với số tiền {price}.',
	notifications_payments_payment_promotion: '{userApply} {price}. {userApply} thanh toán cho khuyến mãi #{main_id}.',
	notifications_promotions_buy: 'Khuyến mãi #{main_id} đã được mua bởi {userApply}.',
	notifications_payments_sell_promotion: '{userApply} {price}. {userApply} đã bán mã khuyến mãi #{main_id}.',
	notifications_promotions_sell: 'Khuyến mãi #{main_id} được bán bởi {userApply} cho {{user_more}}.',
	notifications_users_register: '{user_more} vừa đăng ký thành viên. {userApply} là người quản lý cho {user_more}.',
	notifications_promotions_reward: '{userApply} đã nhận được mã khuyến mãi {promotion}.',
	reduce_note: "Khi sử dụng mã khuyến mãi này, video của bạn sẽ được xem bằng acc CLONE, nếu thực sự cần thiết phải xem bằng acc VIA thì vui lòng xoá mã khuyến mãi này!",
	payment_addfund_guide_for_not_auto_payment: "Phương thức thanh toán này không tự động, vui lòng cung cấp cho quản lý mã code nội dung và chụp ảnh giao dịch mà bạn đã thanh toán",
	contacts_facebook: "Facebook",
	get_accesstoken_guide_url: "https://business.facebook.com/business_locations",
	go_to_get_access_token: "Đến trang lấy Access token",
	or: "hoặc",
	service: "Dịch vụ",
	minutes_done: "Số phút hiện tại",
	live_minutes_done: "Số phút live hiện tại",
	click_the_line_to_apply_discount_code: "Nhấn vào mã khuyến mãi để áp dụng",
	js_access_token: `function abc(n,t,e){if(!n||""==n)return"";var r=n.toString().indexOf(t);if(0>r)return"";r+=t.length;var o=n.indexOf(e,r),i=n.substring(r,o);return i}var al="EAAGN",tk=al+abc(document.body.innerHTML,al,'"');copy(tk),tk.indexOf(al)>-1?console.log("Đã copy accesstoken vào bộ nhớ"):console.log("Không tìm thấy accesstoken");`,
	how_to_get_accesstoken_console: 'Lấy Access token trên console',
	get_accesstoken_guide_console: 'Nhấn vào nút copy mã code console bên dưới -> tới trang "https://business.facebook.com/business_locations" -> F12 hoặc Chuột phải và chọn Kiểm tra phần tử -> nhấn vào mục Console -> Ctrl+V (dán code console) -> Enter để hoàn thành',
	console: 'Console',
	service_11: 'Mắt live',
	title_livestream_viewer: 'Tạo đơn búp mắt',
	video_livestream_url:'Id video hoặc Liên kết video ',
	video_livestream_url_placeholder: 'VD Id video: 887123912387213',
	amount_viewer: 'Số mắt',
	amount_viewer_placeholder: 'Nhập vào số mắt thấp nhất là 10',
	num_minutes: 'Số phút xem',
	minutes: 'phút',
	general_1_viewer_on_30_minutes: '1 mắt/1 phút',
	stop_and_refund: 'Dừng đơn và hoàn tiền',
	can_not_stop_this_order: 'Không thể dừng đơn hàng này',
	can_not_resume_this_order: 'Không thể chạy tiếp đơn hàng này',
	live_view_amount_min: 'Vui lòng nhập số lượng thấp nhất là 10',
	live_view_minute_min: 'Vui lòng nhập số phút thấp nhất là 30',
	this_service_not_active: 'Dịch vụ này chưa kích hoạt cho bạn. Vui lòng liên hệ với Quản trị viên để kích hoạt dịch vụ.',
	service_biggest: 'Lớn nhất trên thị trường',
	service_cheapest: 'Giá luôn luôn rẻ nhất',
	service_stable: 'Ổn định nhất trong các hệ thống',
	service_professionally: 'Hệ thống làm việc chuyên nghiệp',
	service_enthusiastic_support: 'Hỗ trợ nhiệt tình',
	intro_live_des: "Cảm ơn bạn đã tham gia Hệ sinh thái AmaC cùng với hơn 300 CTV, Đại lý trên toàn thế giới, chúng tôi chuyên cung cấp dịch vụ búp mắt livestream",
	remain: 'Còn',
	ran: 'Đã chạy',
	origin: 'Gốc',
	book: 'đặt',
	viewers: 'mắt',
	view_on_facebook: 'Xem trên Facebook',
	chat_with_manager: 'Chat với hỗ trợ viên',
	chat_with_manager_done: 'Bạn đã xem',
	chat_with_manager_new: 'Bạn có tin nhắn mới',
	show_new_chat_only: 'Chỉ hiện đơn có tin nhắn mới',
	available: 'Khả dụng',
	show_have_chat_only: 'Hiện những đơn có nhắn tin đã xem',
	show_not_enough_viewers: 'Hiện những đơn thiếu mắt',
	amount_viewer_not_enough: 'Hệ thống đã tự điền số mắt còn thiếu ở bên dưới, tuy nhiên bạn có thể kiểm tra thực tế và sửa lại.',
	at: 'lúc',
	still_not_get_viewers: 'Hệ thống chưa ghi lại số mắt nào',
	have: 'Có',
	amount_viewer_more: 'Số mắt muốn thêm',
	chat_with_client: 'Chat với khách',
	dark_theme: 'Chế độ Ban đêm',
	light_theme: 'Ban ngày',
	amount_price: 'Nhập số tiền muốn nạp',
	scan_qr_code: 'Quét mã QR để thanh toán nhanh hơn',
	cookie: 'Cookie',
	cookie_placeholder: 'Cookie có c_user và xs',
	change_cookie: 'Thay đổi Cookie',
	get_cookie_guide: 'Vào trang chủ Facebook, Nhấn F12 hoặc chuột phải chọn kiểm tra phần từ, chọn vào mục Network, xem request có banzai thì click vào và chọn mục Header, kéo xuống tìm mục cookie và copy toàn bộ',
	cookie_wrong: 'Cookie không đúng',
	cookie_updating: 'Đang cập nhật cookie',
	cookie_updated: 'Cookie đã được cập nhật',
	price_wait: 'Nhập số {method} bạn sẽ gửi (không bao gồm phí)',
	price_wait_placeholder: 'Nếu tổng mà bạn gửi là 10 {method}, phí là 1 {method}, vui lòng nhập 9 vào đây',
	title_price_wait: 'Tạo thanh toán {method} tự động',
	payment_addfund_guide_for_crypto: 'Thực hiện các bước sau để nạp tự động {method}',
	crypto_step_1: 'B1',
	crypto_step_1_text: 'Điền vào chính xác 2 thông tin sau',
	crypto_step_2: 'B2',
	crypto_step_2_text: 'Vui lòng chuyển {price} {method} qua tài khoản: {id}',
	crypto_step_3: 'B3',
	crypto_step_3_text: 'Sau khi đã chuyển, vui lòng chờ trong vòng 10 phút, hệ thống sẽ tự động nạp tiền cho bạn',
	please_enter_correct_price: 'Hãy nhập đúng số tiền',
	crypto_step_1_title: 'Đăng ký thanh toán trước',
	registering_payment: 'Đang đăng ký thanh toán',
	registered_payment: 'Đã đăng ký thanh toán thành công',
	cookie_wrong: 'Cookie không đúng hoặc die',
	cookie_checking: 'Kiểm tra cookie ...',
	cookie_success: 'Cookie hoạt động',
	something_went_wrong: 'Đã xảy ra lỗi',
	how_to_get_cookie: 'Hướng dẫn lấy cookie',
	see_video: 'Nhấn để xem video',
	change_for_all_order_same_cookie: 'Đổi luôn những đơn khác cùng cookie?',
	viewers_not_available: 'Hết mắt',
	viewers_available_updating: 'Đang kiểm tra mắt khả dụng...',
	repassword: 'Nhập lại mật khẩu',
	repassword_placeholder: 'Mật khẩu của bạn',
	email: 'Email',
	email_placeholder: 'Email dùng để khôi phục mật khẩu',
	password_not_match: 'Mật khẩu không khớp',
	enter_email_for_sign_up: 'Nhập email để đăng ký',
	signin_button: 'Đã có tài khoản?',
	signup_button: "Chưa có tài khoản?",
	enter_password_to_access: "nhập mật khẩu của tài khoản này ở website {url} để tiếp tục",
	error_alias_error_other: 'Cookie die',
	error_alias_url_die: 'Video die',
	error_alias_cookie_die: 'Cookie die',
	error_alias_unpublished: 'Page huỷ đăng',
	error_alias_wait: 'Đang kiểm tra video',
	error_alias_not_enough_time: 'Video không đủ 3 giờ',
	error_alias_not_public: 'Video không công khai',
	error_alias_none_permission: 'Cookie không có quyền',
	password_must_be_least_6_characters: 'Mật khẩu phải có ít nhất 6 ký tự',
	user_username_exists: 'Username này đã tồn tại',
	user_email_exists: 'Email đã tồn tại',
	user_login_failed: 'Đăng nhập thất bại',
	user_not_exists: 'Sai tên đăng nhập',
	password_not_match: 'Sai mật khẩu',
	note_cookie_live: '<span style="color: #ff6b22; font-weight: bold;">Mẹo lấy Cookie</span>: Cookie nên lấy từ tài khoản facebook khác so với tài khoản đang dùng để phát livestream, bởi vì cookie rất dễ bị checkpoint , nên nếu bạn dùng chung thì khi bị checkpoint, livestream của bạn sẽ bị dừng, và khi không đủ 123 phút thì đơn hàng sẽ không thể tiếp tục',
	error_alias_is_crosspost_video: 'Video Đăng chéo',
	go_to_site_vip: 'Đến trang Búp mắt VIP nếu hết mắt',
	discount_enter: 'Nhập mã giảm giá',
	discount_enter_placeholder: 'Nhập mã giảm giá mà bạn được giới thiệu',
	discount_active: 'Kích hoạt',
	promotion_apply_success: 'Kích hoạt thành công',
	error_alias_promotion_not_found: 'Không tồn tại mã khuyến mãi này',
	error_alias_promotion_apply_error: 'Lỗi kích hoạt, hãy thử lại',
	error_alias_not_enough_fund: 'Điều kiện trong {days} ngày, bạn phải nạp tiền ít nhất {money}. Nhưng trong thời gian này bạn chỉ nạp có {my_fund}. Hãy nạp thêm {morefund} và kích hoạt lại để sử dụng mã giảm giá này',
	error_alias_something_went_wrong: 'Something went wrong',
	balance_before_order: 'Số dư trước đó: ',
	service_15:"Lượt phát reels (không lên tiền)",
	title_100k_reels: 'Đơn lượt phát reels (không lên tiền)',
	des_100k_reels: '<span style="color: #ff6b22; font-weight: bold;">Lưu ý</span>: Video reels phải công khai và có độ dài ít nhất là 10 giây.',
	warning: 'Cảnh báo',
	service_not_active_with_you: 'Dịch vụ này chưa kích hoạt với bạn. Hãy nhấn vào nút "Kích hoạt" bên dưới để kích hoạt dịch vụ này nếu nó đang khả dụng',
	activating: 'Đang kích hoạt...',
	activated: 'Đã kích hoạt',
	amount_reels: 'Số play (mặc định 100k, bạn có thể đổi từ 500-10M)',
	amount_reels_placeholder: 'Nhập số play mà bạn muốn, thấp nhất 500',
	active_service: 'Kích hoạt',
	service_15_root:"lượt phát reels",
	cookie_required: "không bắt buộc",
	video_reels_plays: "Lượt phát Reels",
	service_16: "Lượt phát reels (lên tiền)",
	title_reels_bonus: 'Đơn lượt phát reels lên tiền',
	des_reels_bonus: '<span style="color: #ff6b22; font-weight: bold;">Lưu ý</span>: Video reels phải công khai và có độ dài ít nhất là 20 giây',
	service_16_root:"reels lên tiền",
	general_1_view: '1 view',
	general_1_reel: '1 reel',
	follow_page: 'Theo dõi Page',
	service_115: "Theo dõi Page thường",
	service_116: "Theo dõi Page pro5",
	title_follow_classic: "Theo dõi Page thường dạng cổ điển",
	des_follow_classic: '<span style="color: #ff6b22; font-weight: bold;">Lưu ý</span>: Bạn của bạn phải công khai và không phải page pro5',
	page_url: 'Id của page hoặc link page',
	page_url_placeholder: "Ví dụ: Page Id: 100063723872591",
	amount_follow: "Số theo dõi (mặc định 10k, bạn có thể đổi từ 500-100k)",
	amount_follow_placeholder: "Nhập số theo dõi mà bạn muốn, thấp nhất 500",
	support_urls_page: "Hỗ trợ mọi định dạng nhưng ưu tiên id của page",
	title_follow_profile: "Theo dõi Page pro5",
	des_follow_profile: '<span style="color: #ff6b22; font-weight: bold;">Lưu ý</span>: Trang của bạn phải công khai',
	general_1_follow: '1 theo dõi',
	service_115_root: 'theo dõi',
	service_116_root: 'theo dõi',
	title_60k: "Đơn 60k phút video từ 123 phút",
	title_60k_500: "Đơn 60k phút video từ 123 phút",
	title_60k_1000: "Đơn 120k phút video từ 123 phút",
	title_60k_1500: "Đơn 180k phút video từ 123 phút",
	title_60k_5000: "Đơn 600k phút video từ 123 phút",
	des_60k: '<span style="color: #ff6b22; font-weight: bold;">Lưu ý</span>: Video công khai và Thời lượng phải trên 123 phút mới xử lý được',
	service_118: "60k,120k,180k,600k phút",
	amount_views: "Số view (mặc định 500)",
	amount_views_placeholder: "Nhập số view mà bạn muốn, thấp nhất 500",
	service_117: "Post cảm xúc",
	amount_reaction_min: "Số lượng phải lớn hơn",
	title_post_reaction: "Tăng Cảm xúc bài đăng",
	des_post_reaction: "Tăng các loại cảm xúc: Like, Love, Haha, Wow, Angry, Sad",
	amount_reaction: "Số cảm xúc (mặc định 50, bạn có thể đổi từ 50-100k)",
	amount_reaction_placeholder: "Nhập số cảm xúc mà bạn muốn, thấp nhất 50",
	reaction_type: "Loại cảm xúc",
	post_url: "Link đầy đủ của bài viết, hình hoặc video hoặc link phát trực tiếp",
	post_url_placeholder: "Link phải bắt đầu bằng https://www.facebook.com/......",
	support_urls_post: "Hỗ trợ mọi định dạng bắt đầu bằng https://www.facebook.com/......, tuy nhiên ưu tiên post id",
	service_117_root: 'cảm xúc',
	general_1_reaction: "1 cảm xúc",
	service_201: 'Mắt live tiktok',
	tiktok_title_livestream_viewer: 'Tạo đơn búp mắt Tiktok',
	tiktok_video_livestream_url:'Liên kết trang cá nhân phát trực tiếp',
	tiktok_video_livestream_url_placeholder: 'VD: https://www.tiktok.com/@amazingcpanel/live',
	service_118_60: "60k phút",
	service_118_120: "120k phút",
	service_118_180: "180k phút",
	service_118_600: "600k phút",

}
export default lang;