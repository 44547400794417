<template>
  <f7-page
name="messages"
  ptr @ptr:refresh="loadMore"

      style="padding-bottom: 94px;">
    <f7-navbar :title="'# '+data_order._id+' | '+author_order_username" :back-link="$root.setLang('back')">
      <f7-nav-right>
      <f7-link icon-only :href="data_order.permalink_url" target="_blank" external class="icon-tooltip-view-on-facebook">
        <f7-icon ios="f7:logo_facebook" aurora="f7:logo_facebook" md="f7:logo_facebook">
        </f7-icon>
      </f7-link>
    </f7-nav-right>
    </f7-navbar>

    <f7-messagebar
      :placeholder="placeholder"
      ref="messagebar"
      :attachments-visible="attachmentsVisible"
      :sheet-visible="sheetVisible"
      @keypress="testMessage"
    >
      <f7-link
        icon-ios="f7:link"
        icon-aurora="f7:link"
        icon-md="f7:link"
        slot="inner-start"
        v-clipboard:copy="data_order.video_id"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  class="icon-tooltip-video-id"
      ></f7-link>
      <f7-link
        icon-ios="f7:arrow_up_circle_fill"
        icon-aurora="f7:arrow_up_circle_fill"
        icon-md="material:send"
        slot="inner-end"
        @click="sendMessage"
      ></f7-link>
      <!-- <f7-messagebar-attachments>
        <f7-messagebar-attachment
          v-for="(image, index) in attachments"
          :key="index"
          :image="image"
          @attachment:delete="deleteAttachment(image)"
        ></f7-messagebar-attachment>
      </f7-messagebar-attachments>
      <f7-messagebar-sheet>
        <f7-messagebar-sheet-image
          v-for="(image, index) in images"
          :key="index"
          :image="image"
          :checked="attachments.indexOf(image) >= 0"
          @change="handleAttachment"
        ></f7-messagebar-sheet-image>
      </f7-messagebar-sheet> -->
    </f7-messagebar>

    <f7-messages ref="messages">
      <f7-messages-title><b>{{getHeader(data_order)}}</b></f7-messages-title>
      <f7-message
        v-for="(message, index) in messagesData"
        :key="index"
        :type="message.type"
        :image="message.image"
        :name="message.name"
        :avatar="message.avatar"
        :first="isFirstMessage(message, index)"
        :last="isLastMessage(message, index)"
        :tail="isTailMessage(message, index)"
      >
        <span slot="text" v-if="message.text" v-html="message.text"></span>
      </f7-message>
      <f7-message v-if="typingMessage"
        type="received"
        :typing="true"
        :first="true"
        :last="true"
        :tail="true"
        :header="`${typingMessage.name} is typing`"
        :avatar="typingMessage.avatar"
      ></f7-message>
    </f7-messages>
  </f7-page>
</template>

<style type="text/css">
  .messagebar{
    bottom: 50px;
  }
</style>
<script>
  export default {
    data() {
      const self = this;
      // console.log(self.$f7route.query.data)
      var data_order = JSON.parse(self.$f7route.query.data);
      var room_id = data_order._id;//self.$root.getString(self.$f7route.url,'/message/','/');
      var author_order = data_order.user;//self.$root.getString(self.$f7route.url,'/user/','/');
      var author_order_username = author_order.username;
      // author_order.username = self.encode_utf8(author_order.username);
      // author_order.displayName = self.encode_utf8(author_order.displayName);
      delete author_order.username
      delete author_order.displayName;
      return {
        attachments: [],
        sheetVisible: false,
        typingMessage: null,
        allowInfinite: true,
        showPreloader: true,
        is_loading: false,
        messagesData: [
          // {
          //   type: 'sent',
          //   text: 'Hi, Kate',
          // },
          // {
          //   type: 'sent',
          //   text: 'How are you?',
          // },
          // {
          //   name: 'Kate',
          //   type: 'received',
          //   text: 'Hi, I am good!',
          //   avatar: 'https://cdn.framework7.io/placeholder/people-100x100-9.jpg',
          // },
          // {
          //   name: 'Blue Ninja',
          //   type: 'received',
          //   text: 'Hi there, I am also fine, thanks! And how are you?',
          //   avatar: 'https://cdn.framework7.io/placeholder/people-100x100-7.jpg',
          // },
          // {
          //   type: 'sent',
          //   text: 'Hey, Blue Ninja! Glad to see you ;)',
          // },
          // {
          //   type: 'sent',
          //   text: 'Hey, look, cutest kitten ever!',
          // },
          // {
          //   type: 'sent',
          //   image: 'https://cdn.framework7.io/placeholder/cats-200x260-4.jpg',

          // },
          // {
          //   name: 'Kate',
          //   type: 'received',
          //   text: 'Nice!',
          //   avatar: 'https://cdn.framework7.io/placeholder/people-100x100-9.jpg',
          // },
          // {
          //   name: 'Kate',
          //   type: 'received',
          //   text: 'Like it very much!',
          //   avatar: 'https://cdn.framework7.io/placeholder/people-100x100-9.jpg',
          // },
          // {
          //   name: 'Blue Ninja',
          //   type: 'received',
          //   text: 'Awesome!',
          //   avatar: 'https://cdn.framework7.io/placeholder/people-100x100-7.jpg',
          // },
        ],
        images: [
        ],
        people: [
          
        ],
        answers: [
        ],
        responseInProgress: false,
        data_order: data_order,
        room_id: room_id,
        author_order: author_order,
        author_order_username: author_order_username,
        messagebar_val: 1,
      };
    },
    // sockets: {
    //   join: function (message) {
    //     // console.log('message',message)
    //     // this.message = {
    //     //   text: message.text,
    //     //   author: 'Bot',
    //     //   date: moment(new Date()).format('h:mm'),
    //     // }
    //   },
    //   $destroy: function(message){
    //     console.log('destroy')
    //   },
    //   connect: function () {
    //         console.log('socket connected')
    //     },
    //     customEmit: function (data) {
    //         console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    //     },
    //   message: function (message) {
    //     const self = this;
    //     if(message.room == self.room_id){
    //       self.messagebar = self.$refs.messagebar.f7Messagebar;
    //       self.messages = self.$refs.messages.f7Messages;
    //       var text = self.decode_utf8(message.text);
    //       var person = message.user.username;
    //       if(self.$f7.data.user.username == person){
    //         const messagesToSend = [];
    //         self.attachments.forEach((attachment) => {
    //           messagesToSend.push({
    //             image: attachment,
    //           });
    //         });
    //         if (text.trim().length) {
    //           messagesToSend.push({
    //             text,
    //           });
    //         }
    //         if (messagesToSend.length === 0) {
    //           return;
    //         }
    //         // Reset attachments
    //         self.attachments = [];
    //         // Hide sheet
    //         self.sheetVisible = false;
    //         // Clear area
    //         self.messagebar.clear();
    //         // Focus area
    //         if (text.length) self.messagebar.focus();
    //         // Send message
    //         self.messagesData.push(...messagesToSend);
    //           self.responseInProgress = false;
    //       }else{

    //         // // Mock response
    //         // if (self.responseInProgress) return;
    //         // self.responseInProgress = true;
    //         setTimeout(() => {
    //           self.typingMessage = {
    //             name: person,
    //             avatar: message.user.profileImageURL,
    //           };
    //             setTimeout(() => {
    //               self.messagesData.push({
    //                 text: text,
    //                 type: 'received',
    //                 name: person,
    //                 avatar: self.$root.socket_url+'/'+message.user.profileImageURL,
    //               });
    //               self.typingMessage = null;
    //               self.responseInProgress = false;
    //             }, 500);
              
    //         }, 1000*0);
    //       }
    //       self.seenMessage();
    //     }
    //     // this.message = {
    //     //   text: message.text,
    //     //   author: message.username,
    //     //   date: moment(new Date()).format('h:mm'),
    //     // }
    //   },

    //   messageall: function (message) {
    //     // const self = this;

    //     // self.messagebar = self.$refs.messagebar.f7Messagebar;
    //     // self.messages = self.$refs.messages.f7Messages;
    //     // var text = self.decode_utf8(message.text);
    //     // var person = message.user.username;
    //     // self.$root.newMessage({
    //     //   title: person,
    //     //   text: text,
    //     //   profileImageURL: self.$root.socket_url+'/'+message.user.profileImageURL
    //     // })
    //     // if((self.$f7.data.user.roles.indexOf('admin') == -1 && self.$f7.data.user._id == message.main_room) || (self.$f7.data.user.roles.indexOf('admin') > -1 && "message_admin" == message.main_room)){
          
    //     // }
    //   },
    // },

    computed: {
      attachmentsVisible() {
        const self = this;
        return self.attachments.length > 0;
      },
      placeholder() {
        const self = this;
        return self.attachments.length > 0 ? 'Add comment or Send' : 'Message';
      },
    },
    mounted() {
      const self = this;
        var app = self.$f7;
      self.$f7ready(() => {
        self.messagebar = self.$refs.messagebar.f7Messagebar;
        self.messages = self.$refs.messages.f7Messages;
        self.messagebar_val = 2;
// console.log('ok',self.messagebar)
        // self.$socket.emit('join', {room: self.room_id})
        self.$root.socket.emit('join', {room: self.room_id})
        self.loadTooltip();
        // self.sockets.subscribe('connect', (data) => {
        //     // console.log(data)
        // });
        // self.$root.socket.on('messageall', (message) => {
        //   console.log('messageall')
        // });
        self.$root.socket.on('messageall', (message) => {
          if(message.room == self.room_id){
            var messagebar = app.messagebar.get('.messagebar')
           // const self = this;
            // console.log('ok',self.messagebar,messagebar)
            self.messagebar = messagebar;
            // self.messages = self.$refs.messages.f7Messages;
            var text = self.decode_utf8(message.text);
            var person = message.user.username;
            if(self.$f7.data.user.username == person){
              const messagesToSend = [];
              self.attachments.forEach((attachment) => {
                messagesToSend.push({
                  image: attachment,
                });
              });
              if (text.trim().length) {
                messagesToSend.push({
                  text,
                });
              }
              if (messagesToSend.length === 0) {
                return;
              }
              // Reset attachments
              self.attachments = [];
              // Hide sheet
              self.sheetVisible = false;
              // Clear area
              self.messagebar.clear();
              // Focus area
              if (text.length) self.messagebar.focus();
              // Send message
              self.messagesData.push(...messagesToSend);
                self.responseInProgress = false;
            }else{

              // Mock response
              if (self.responseInProgress) return;
              self.responseInProgress = true;
              setTimeout(() => {
                self.typingMessage = {
                  name: person,
                  avatar: message.user.profileImageURL,
                };
                  setTimeout(() => {
                    self.messagesData.push({
                      text: text,
                      type: 'received',
                      name: person,
                      avatar: self.$root.socket_url+'/'+message.user.profileImageURL,
                    });
                    self.typingMessage = null;
                    self.responseInProgress = false;
                  }, 500);
                
              }, 1000*0);
            }
            self.seenMessage();
          }
        })
        self.$$('.messagebar textarea').on('keypress', function (handler) {
          if(handler.key == "Enter"){
            return self.sendMessage();
          }
        });
      });
    },
    methods: {
      testMessage(){
        console.log('testMessage')
      },
      seenMessage(){
        const self = this;
        var app = self.$f7;
        var message = {
          type: 'seen',
          room: self.room_id,
          author_order: self.author_order
        };
        // self.$socket.emit('seen', message)
        self.$root.socket.emit('seenview', message)
      },
      loadTooltip(){
        const self = this;
        var app = self.$f7;
        var minutesTooltip = app.tooltip.create({
          targetEl: '.icon-tooltip-view-on-facebook',
          text: self.$root.setLang('view_on_facebook')
        });
        var liveminutesTooltip = app.tooltip.create({
          targetEl: '.icon-tooltip-video-id',
          text: self.$root.setLang('copy_id_video')
        });
        self.loadMoreMessage(function(messages){
          for (var i = 0; i < messages.length; i++) {
            if(messages[i].user._id == self.$f7.data.user._id){
              self.messagesData.push({
                type: 'sent',
                text: self.decode_utf8(messages[i].text),
              })
            }else{

              self.messagesData.push({
                name: messages[i].user.username,
                type: 'received',
                text: self.decode_utf8(messages[i].text),
                avatar: self.$root.socket_url+'/'+messages[i].user.profileImageURL,
              })
            }
            
          }
        })
      },

      getHeader(order){
        const self = this;
        var d = new Date(order.created);
        return self.formatDate(d);//+' by '+ order.user.username
      },
      formatDate(date) {
        // var monthNames = [
        //   "Jan", "Feb", "Mar",
        //   "Apr", "May", "Jun", "Jul",
        //   "Aug", "Sep", "Oct",
        //   "Nov", "Dec"
        // ];
        var monthNames = [
          "1", "2", "3",
          "4", "5", "6", "7",
          "8", "9", "10",
          "11", "12"
        ];

        var minute = date.getMinutes();
        if(minute < 10){
          minute = '0'+minute
        }
        var hour = date.getHours();
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        // return  hour + ':' +minute + ' ' +  monthNames[monthIndex]+ ' ' +day ;
        return  day + '/' +  monthNames[monthIndex] + ' '+hour + ':' +minute ;
      },
      loadMoreMessage(callback) {
        const self = this;
        var app = self.$f7;
        app.request.post(self.$root.$f7.params.apiurl, {action: 'messageslist', supplier: self.$root.supplier, token: app.data.user.token, skip: self.messagesData.length, id: self.data_order._id, limit: 50, type: "view"}, function(data){
          var orders = data.data;
          if(orders.length > 0){
            self.seenMessage();
          }
          callback(orders);
        },
        function(err){
          callback(false);
        },'json');
      },
      loadMore(done) {
        const self = this;
        self.is_loading = true;
        self.showPreloader = true;
        self.loadMoreMessage(function(messages){
          var messagesData = [];
          for (var i = 0; i < messages.length; i++) {
            if(messages[i].user._id == self.$f7.data.user._id){
              messagesData.push({
                type: 'sent',
                text: self.decode_utf8(messages[i].text),
              })
            }else{

              messagesData.push({
                name: messages[i].user.username,
                type: 'received',
                text: self.decode_utf8(messages[i].text),
                avatar: self.$root.socket_url+'/'+messages[i].user.profileImageURL,
              })
            }
            
          }
          messagesData = messagesData.concat(self.messagesData);
          self.messagesData = messagesData;
          if(typeof done === "function"){
                done();
              }
        })
      },
      isFirstMessage(message, index) {
        const self = this;
        const previousMessage = self.messagesData[index - 1];
        if (message.isTitle) return false;
        if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
        return false;
      },
      isLastMessage(message, index) {
        const self = this;
        const nextMessage = self.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      },
      isTailMessage(message, index) {
        const self = this;
        const nextMessage = self.messagesData[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
        return false;
      },
      deleteAttachment(image) {
        const self = this;
        const index = self.attachments.indexOf(image);
        self.attachments.splice(index, 1)[0]; // eslint-disable-line
      },
      handleAttachment(e) {
        const self = this;
        const index = self.$$(e.target).parents('label.checkbox').index();
        const image = self.images[index];
        if (e.target.checked) {
          // Add to attachments
          self.attachments.unshift(image);
        } else {
          // Remove from attachments
          self.attachments.splice(self.attachments.indexOf(image), 1);
        }
      },
      encode_utf8(s) {
        return unescape(encodeURIComponent(s));
      },

      decode_utf8(s) {
        const self = this;
        // return s;
        return self.$root.decode_utf8(s) //decodeURIComponent(escape(s));
      },
      sendMessage() {
        const self = this;
        // Mock response
        if (self.responseInProgress) return;
        self.responseInProgress = true;
        var text = self.messagebar.getValue().replace(/\n/g, '<br>').trim();
        text = self.encode_utf8(text);
        if(text && text.trim() != ''){
          var message = {
            text: self.decode_utf8(text),
            room: self.room_id,
            author_order: self.author_order
          };
          // console.log(message)
          // self.$socket.emit('join', {room: self.room_id})
          // self.$socket.emit('message', message)
          self.$root.socket.emit('messageview', message)
          // self.responseInProgress = true;
        }
      },
    },
  };
</script>