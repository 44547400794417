<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title >{{$f7.name}}</f7-nav-title>
      <!-- <f7-nav-right>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>
      </f7-nav-right> -->
      <f7-nav-right>
      <f7-link icon-only href="/notifications/">
        <f7-icon ios="f7:bell" aurora="f7:bell" md="material:notifications_none">
          <f7-badge color="red" v-if="$f7.data.user.notification">{{($f7.data.user.notification < 100)? $f7.data.user.notification: '99+'}}</f7-badge>
        </f7-icon>
      </f7-link>
    </f7-nav-right>
    </f7-navbar>
    <!-- <f7-navbar :sliding="false" large>
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title sliding>{{$f7.name}}</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>
      </f7-nav-right>
      <f7-nav-title-large sliding>{{$f7.name}}</f7-nav-title-large>
    </f7-navbar> -->
    <f7-block strong style="
    margin: 20px;
    height: 200px;
    padding: 40px 26px 20px 26px;
    border-radius: 10px;
    box-shadow: 0 12px 20px rgba(160, 158, 158, 0.3);
    box-shadow: rgb(72 71 71 / 15%) 0px 12px 20px;
    ">
      <f7-row>

        <f7-col style="text-align: left;
    top: 10px;
    left: 25px;
    position: absolute;">
    {{$root.setLang('hi')}} <span class="text-color-deeporange">{{($f7.data.user && $f7.data.user.username)}}</span>
        </f7-col>
        <f7-col style="text-align: right;
    top: 10px;
    right: 25px;
    position: absolute;">
    {{$root.setLang('balance')}}: <span class="text-color-deeporange">{{showBalance()}}</span>
        </f7-col>
      </f7-row>
      <f7-row style="    border-top: 1px solid #9e9e9e38;
    padding-top: 40px;" :color="($root.darkTheme)?'white':'black'">
        <f7-col style="text-align: center;">
          <f7-link href="/addfund/" class="navigation_list">
          <i class="fas fa-wallet " style="color: #FF5722;"></i>
          <span>{{$root.setLang('add_fund')}}</span>
        </f7-link>
        </f7-col>
        <f7-col style="text-align: center;">
          <f7-link href="/services/" class="navigation_list">
          <i class="fas fa-clipboard-list" style="color: #607d8b;"></i>
          <span>{{$root.setLang('price_list')}}</span>
        </f7-link>
        </f7-col>
        <f7-col style="text-align: center;">
          <f7-link href="/promotions/" class="navigation_list">
          <i class="fas fa-crown" style="color: #daac23;"></i>
          <span>{{$root.setLang('discount')}}</span>
        </f7-link>
        </f7-col>
        <f7-col style="text-align: center;">
          <f7-link href="#" class="navigation_list myButton" @click="likeBoss">
            <f7-icon class="fas fa-user-tie" :color="($root.darkTheme)?'gray':'black'"></f7-icon>
            <f7-icon class="fas fa-user-secret" :color="($root.darkTheme)?'gray':'black'"></f7-icon>
         <!--  <i class="fas fa-user-tie" color="black"></i> -->
          <!-- <i class="fas fa-user-secret" style="    color: #000;"></i> -->
          <span>Like a Boss</span>
        </f7-link>
        </f7-col>

        <!-- <f7-col style="text-align: center;">
          <f7-link href="/payments/" class="navigation_list">
          <i class="fas fa-funnel-dollar" style="color: #000;"></i>
          <span>Lịch sử giao dịch</span>
        </f7-link>
        </f7-col> -->
      </f7-row>
    </f7-block>

    <div ref="my_body" class="my_body" v-show="likeABoss" @click="likeBoss">
      <input type="checkbox" />
      <div>
      <h1>BOS<span><b>Y</b></span><span><b>E</b></span><span>S</span></h1>
      <h2 style="text-align: center;
    color: #fff;
    font-size: 20px;
    position: absolute;">
        
    <a :href="getManageSite()" target="_blank" class="link external" @click="gotoBoss()" style="position: relative;
    z-index: 9999;color: #fff;">{{$root.setLang('manage_orders_here')}}:</a>
      <a :href="getManageSite()" target="_blank" class="link external manager_site" @click="gotoBoss()">{{getManageSite()}}</a>
      </h2>
      </div>
    </div>
    <!-- <div v-show="$f7.data.userStatic.paymentCountToday <= 0 || $f7.data.userStatic.paymentCount <= 0 || $f7.data.userStatic.orderCountToday >= 0 || $f7.data.userStatic.orderCount >= 0 || $f7.data.userStatic.userCountToday >= 0 || $f7.data.userStatic.userCount >= 0 || $f7.data.userStatic.orderChildCountToday >= 0 || $f7.data.userStatic.orderChildCount >= 0"> -->
      <div v-show="$f7.data.userStatic.paymentCount < 0 || $f7.data.userStatic.orderCount > 0 || $f7.data.userStatic.userCount > 0 || $f7.data.userStatic.orderChildCount > 0">
    <f7-block-title>{{$root.setLang('statistic_today_all')}}</f7-block-title>
    <f7-block strong style="">
      <f7-row>
        <f7-col width="100" medium="100">{{$root.setLang('you_spent')}}: <span class="text-color-deeporange">{{(typeof $f7.data.userStatic.paymentCountToday  != 'undefined')? $root.setPrice(-$f7.data.userStatic.paymentCountToday): '...'}}</span> / <span class="text-color-deeporange">{{(typeof $f7.data.userStatic.paymentCount != 'undefined')? $root.setPrice(-$f7.data.userStatic.paymentCount): '...'}}</span></f7-col>
        <f7-col width="100" medium="100">{{$root.setLang('your_orders')}}: <span :class="'text-color-'+$root.color_theme">{{(typeof $f7.data.userStatic.orderCountToday != 'undefined')?$f7.data.userStatic.orderCountToday: '...'}}</span> / <span :class="'text-color-'+$root.color_theme">{{(typeof $f7.data.userStatic.orderCount != 'undefined')?$f7.data.userStatic.orderCount: '...'}}</span>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100" medium="100">{{$root.setLang('your_clients')}}: <span :class="'text-color-'+$root.color_theme">{{(typeof $f7.data.userStatic.userCountToday != 'undefined')?$f7.data.userStatic.userCountToday: '...'}}</span> / <span :class="'text-color-'+$root.color_theme">{{(typeof $f7.data.userStatic.userCount != 'undefined')?$f7.data.userStatic.userCount: '...'}}</span></f7-col>
        <f7-col width="100" medium="100">{{$root.setLang('orders_from_your_client')}}: <span :class="'text-color-'+$root.color_theme">{{(typeof $f7.data.userStatic.orderChildCountToday != 'undefined')?$f7.data.userStatic.orderChildCountToday: '...'}}</span> / <span :class="'text-color-'+$root.color_theme">{{(typeof $f7.data.userStatic.orderChildCount != 'undefined')?$f7.data.userStatic.orderChildCount: '...'}}</span>
        </f7-col>
      </f7-row>
    </f7-block>
    </div>
    <f7-block-title>{{$root.setLang('intro')}}</f7-block-title>
    <f7-block strong>
       <p>{{$root.setLang('intro_des')}}:
      </p> 
      <div>
        <f7-icon ios="f7:checkmark_shield_fill" aurora="f7:checkmark_shield_fill" md="f7:checkmark_shield_fill" style="font-size: 20px;" :color="$root.color_theme"></f7-icon> {{$root.setLang('service_111')}}
      </div>
      <div>
        <f7-icon ios="f7:checkmark_shield_fill" aurora="f7:checkmark_shield_fill" md="f7:checkmark_shield_fill" style="font-size: 20px;" :color="$root.color_theme"></f7-icon> {{$root.setLang('service_118')}}
      </div>
      <div>
        <f7-icon ios="f7:checkmark_shield_fill" aurora="f7:checkmark_shield_fill" md="f7:checkmark_shield_fill" style="font-size: 20px;" :color="$root.color_theme"></f7-icon> {{$root.setLang('service_68')}}
      </div>
      <div>
        <f7-icon ios="f7:checkmark_shield_fill" aurora="f7:checkmark_shield_fill" md="f7:checkmark_shield_fill" style="font-size: 20px;" :color="$root.color_theme"></f7-icon> {{$root.setLang('service_60')}}
      </div>
      <div>
        <f7-icon ios="f7:checkmark_shield_fill" aurora="f7:checkmark_shield_fill" md="f7:checkmark_shield_fill" style="font-size: 20px;" :color="$root.color_theme"></f7-icon> {{$root.setLang('service_15')}}
      </div>
      <div>
        <f7-icon ios="f7:checkmark_shield_fill" aurora="f7:checkmark_shield_fill" md="f7:checkmark_shield_fill" style="font-size: 20px;" :color="$root.color_theme"></f7-icon> {{$root.setLang('service_16')}}
      </div>
      <div>
        <f7-icon ios="f7:checkmark_shield_fill" aurora="f7:checkmark_shield_fill" md="f7:checkmark_shield_fill" style="font-size: 20px;" :color="$root.color_theme"></f7-icon> {{$root.setLang('service_115')}}
      </div>
      <div>
        <f7-icon ios="f7:checkmark_shield_fill" aurora="f7:checkmark_shield_fill" md="f7:checkmark_shield_fill" style="font-size: 20px;" :color="$root.color_theme"></f7-icon> {{$root.setLang('service_116')}}
      </div>
    </f7-block>
    <!-- <f7-list>
      <f7-list-item link="/about/" title="About"></f7-list-item>
      <f7-list-item link="/form/" title="Form"></f7-list-item>
    </f7-list> -->

  </f7-page>
</template>
<script>
  import io from 'socket.io-client';
  export default {
    data() {
        const self = this;
        var app = self.$f7;
      return {

        socket: io(self.$root.socket_url, {
          reconnection:true,
          reconnectionAttempts: Infinity,
          reconnectionDelayMax: 20000,
          timeout: 30000,
          // auth: {
          //   token: "123"
          // },
          query: {
            "token": app.data.user.token
          }
        }),
        orders: [],
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        allowInfinite: true,
        showPreloader: true,
        likeABoss: false,
      };
    },
    // on: {
    //   pageInit: function () {
    //     var self = this;
    //     var app = self.$f7;
    //     // request user data on page init
    //     app.request.get(self.$root.$f7.params.apiurl+'videovieworderslist', (user) => {
    //       // update component state with new state
    //       self.$setState({
    //         user: user,
    //       });
    //     });
    //   },
    // },
    on:{
      pageMounted: function (e, page) {
    console.log('page mounted');
  },
  pageInit: function (e, page) {
    console.log(this.username); // -> 'johndoe'
  },
  pageBeforeIn: function (e, page) {
    console.log('page before in');
  },
  pageAfterIn: function (e, page) {
    console.log('page after in');
  },
  pageBeforeOut: function (e, page) {
    console.log('page before out');
  },
  pageAfterOut: function (e, page) {
    console.log('page after out');
  },
  pageBeforeUnmount: function (e, page) {
    console.log('page before unmount');
  },
  pageBeforeRemove: function (e, page) {
    console.log('page before remove');
  },
      
    },

    created() {
        const self = this;
        self.$root.socket = self.socket;
        var app = self.$f7;
      // console.log("ok")
      
        // this.socket.on('joinall', (data) => {
        //  // this.users = data.users
        //  console.log(data)
        // })
        this.socket.on('connect', () => {
          this.socket.emit('joinall', {})
        });
        this.socket.on('connect_error', () => {
          // console.log('error');
        });
        this.socket.on('connect_timeout', () => {
          // console.log('connect_timeout')
        });
        this.socket.on('messageall', (message) => {
          // this.users = data.users
          // console.log(data)
          if(message.service_type && message.service_type == "view"){
            var text = self.$root.decode_utf8(message.text);
            var person = message.user.username;
            if(self.$f7.data.user.username != person){
              self.$root.newMessage({
                title: app.name,
                subtitle: person,
                text: text,
                // profileImageURL: self.$root.socket_url+'/'+message.user.profileImageURL
                profileImageURL: '/static/images/viewer_livestream2.ico'
              })
            }
          }
        })
      },
    methods: {
      likeBoss(){
        const self = this;
        if(!self.likeABoss){
          self.likeABoss = !self.likeABoss;
          var textEl = self.$refs["my_body"];
          setTimeout(function(){
            textEl.querySelector('input').checked = true;
          }, 100)
        }else{
          var textEl = self.$refs["my_body"];
          textEl.querySelector('input').checked = false;
          setTimeout(function(){
            self.likeABoss = !self.likeABoss;
          }, 400)
        }
      },
      gotoBoss(){
        return false;
      },
      getManageSite(){
        const self = this;
        var domain = location.host;
        if(self.$root.managerSite[domain]){
          return self.$root.managerSite[domain];
        }
        return self.$root.managerSite['*']
      },
      showBalance(){
        const self = this;
        var app = self.$f7;
        var balance = "...";
        if(app.data.userbalance.balances && app.data.userbalance.balances.length){
          balance = 0;
          for (var i = 0; i < app.data.userbalance.balances.length; i++) {
            if(self.$root.services.indexOf(app.data.userbalance.balances[i].service_id._id) != -1){
              balance += app.data.userbalance.balances[i].value;
            }
          }
          return self.$root.setPrice(balance);//self.$root.setPrice(app.data.user.balance)
        }
        return balance;
        
      },
      loadMore() {
        const self = this;
        if (!self.allowInfinite) return;
        self.allowInfinite = false;
self.loadData()
        setTimeout(() => {
          if (self.items.length >= 200) {
            self.showPreloader = false;
            return;
          }

          const itemsLength = self.items.length;

          for (let i = 1; i <= 20; i += 1) {
            self.items.push(itemsLength + i);
          }

          self.allowInfinite = true;
        }, 1000);
      },
      loadData(){
        var self = this;
        var app = self.$f7;
        // request user data on page init
        app.request.get(self.$root.$f7.params.apiurl+'videovieworderslist', (user) => {
          // update component state with new state
          self.$setState({
            user: user,
          });
        });
        // var app = self.$app;
        // app.request.get('blog-post.php', { foo:'bar', id: 5 }, function (data) {
        //   console.log('Load was performed', data);
        // });
      },
    },
    mounted() {
        var self = this;
        var app = self.$f7;
    },
  };
</script>