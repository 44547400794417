<template>
  <f7-page name="promotions"  @page:init="loadRefresh"
  ptr @ptr:refresh="loadRefresh"
  >

    <f7-navbar :title="$root.setLang('offer_for_you')" :back-link="$root.setLang('back')"></f7-navbar>

<f7-row>
    <f7-col width="100" small="100" large="100">
    <f7-list inline-labels no-hairlines-md  style="margin: 0;">
        <f7-list-input
          :label="$root.setLang('discount_enter')"
          type="text"
          name="promotion_apply"
          :value="promotion_apply"
          @input="promotion_apply = $event.target.value"
          :placeholder="$root.setLang('discount_enter_placeholder')"
        >
        </f7-list-input><f7-button @click="activepromotion()"
                  style="    display: inline-block;
                position: absolute;
                top: 8px;
                border: 1px solid #e3e3e3;
                right: 10px;"
                  >{{$root.setLang('discount_active')}}</f7-button>
    </f7-list>
      </f7-col>
  </f7-row>
    <f7-block-title>{{$root.setLang('click_the_line_to_copy_discount_code')}}</f7-block-title>
    <f7-list media-list>
    <f7-list-item
        v-for="(promotion, index) in promotions"
        :key="index"
        :subtitle="``"
        :title="promotion.code"
        :header="$root.getDesPromotion(promotion, false)"
        :text="$root.setLang('expiry_date_until_end_of_day')+` `+$root.formatDatePromotion(promotion.expired_date)"
        :badge="$root.getCoverPromotion(promotion)"
        badge-color="deeporange"
        @click="$root.copyText(promotion.code)"
        >
        <div slot="before-title" style="    margin-right: 10px;">{{$root.setLang('discount_code')}}: </div>
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
        promotions: [],
        allowInfinite: true,
        showPreloader: true,
        is_loading: false,
        page: 2,
        promotion_apply: '',
      };
    },
    methods: {
      intialized() {
        console.log('initialized')
      },
      // loadMore(){
      //   const self = this;
      //   console.log(self.$root.setLang('key_lange', 'Order #{main_id} by {userApply} had been created.'))
      //   self.notifications = this.$f7.data.notifications;

      //   console.log(self.notifications)
      //   console.log('ok nha')
      // },
      loadRefresh() {
        const self = this;
        if(self.showPreloader && !self.is_loading){
          self.is_loading = true;
          if (!self.allowInfinite) return;
          self.allowInfinite = false;
            if (self.promotions.length >= 5000) {
              self.showPreloader = false;
              return;
            }
            // self.loadData()
            self.$root.loadMorePromotions(function(promotions){
              if(promotions){
                if(promotions.length == 0){
                  self.showPreloader = false;
                }
                self.promotions = promotions;
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
            })
            
        }
      },
      activepromotion(){
        const self = this;
        var app = self.$f7;
        app.dialog.progress(self.$root.setLang('request_sending'));
        self.$root.activePromotion(self.promotion_apply,function(data){
          if(data.status == "error"){

            self.$f7.dialog.close();
            if(data.data){
              app.dialog.alert(self.$root.setLang('error_alias_'+data.error_alias).replace('{days}',data.data.fund_days).replace('{money}',self.$root.setPrice(data.data.fund_money)).replace('{my_fund}',self.$root.setPrice(data.my_fund)).replace('{morefund}',self.$root.setPrice(data.data.fund_money-data.my_fund+1)), self.$root.setLang('failed'));
            }else{
              app.dialog.alert(self.$root.setLang('error_alias_'+data.error_alias), self.$root.setLang('failed'));
            }
            // setTimeout(() => {
            //   self.$f7.dialog.close();
            // }, 500);
          }else{
            self.$f7.dialog.close();
            app.dialog.alert(self.$root.setLang('promotion_apply_success'), self.$root.setLang('success'));
            setTimeout(() => {
              self.$f7.dialog.close();
            }, 1000);
            self.$root.loadMorePromotions(function(promotions){
              if(promotions){
                if(promotions.length == 0){
                  self.showPreloader = false;
                }
                self.promotions = promotions;
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
            })
          }
        })
        
      },
    },
    mounted () {
    },
  };
</script>