var site = {
	name: "Búp mắt",
	supplier: '5ce8260f4b8f960e5c0d09f0',
	lang_default: 'vn',
	managerSite: 'https://fbvideoview.com/',
  service_alias: 'live',
	services: ["5d4af99344156a12f8173de5"],
	paymentData: {
        'Vietcombank' : {
          'status': true,
          'selected': true,//set default show
          'id': '0381000438756',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Vietcombank',
          'min': 2.5,
          'auto': true,
        },
        // 'Momo' : {
        //   'status': true,
        //   'id': '0765152065',
        //   'name': 'Nguyen Van Thinh',
        //   'bank_name': 'Momo',
        //   'min': 2.5,
        //   'auto': true,
        // },
        'Paypal' : {
          'status': false,
          'id': 'adbreaksvietnam@gmail.com',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Paypal',
          'min': 5,
          'auto': true,
        },
        'Payoneer' : {
          'status': false,
          // 'selected': true,//set default show
          'id': 'vanthinh291@gmail.com',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Payoneer',
          'min': 5,
          'auto': true,
        },
        'PerfectMoney' : {
          'status': true,
          'selected': false,
          'id': 'U15258486',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Perfect Money',
          'min': 5,
          'auto': true,
        },
        'Binance' : {
          'status': true,
          'selected': false,//set default show
          'id': '397734662',
          'name': 'vanthinh291@gmail.com',
          'bank_name': 'Binance (USDT TRC20)',
          'min': 5,
          'auto': true,
        },
        'Skrill' : {
          'status': true,
          'id': 'vanthinh291@gmail.com',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Skrill',
          'min': 5,
          'auto': true,
        },
        'USDTTRC20' : {
          'status': true,
          'selected': false,//set default show
          'id': 'TGHVKViaW5nea2SZe2dNcjDW961a7qUrPr',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'USDT TRC20',
          'min': 5,
          'auto': true,
        },
        'Techcombank' : {
          'status': false,
          'id': '19034287915015',
          'name': 'Cao Thi Kim Phuong',
          'bank_name': 'Techcombank',
          'min': 5,
          'auto': true,
        },
        'Bitcoin' : {
          'status': true,
          'selected': false,//set default show
          'id': '33GsV9uo6QvMv38uK9tY3TNm1n2JvjkDNM',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Bitcoin',
          'min': 5,
          'auto': true,
        },
        'Ethereum' : {
          'status': true,
          'selected': false,//set default show
          'id': '0x5Dc519eDf094BfEeD2c94cCfae0A47025efb4A99',
          'name': 'Nguyen Van Thinh',
          'bank_name': 'Ethereum TRC20',
          'min': 5,
          'auto': true,
        },
    },
    contacts: {
        'phone': {
          'id': '+84975308144',
          'name': 'Phone, Zalo, WhatsApp, Telegram:',
        },
        'email': {
          'id': 'amazingcpanel@gmail.com',
          'name': 'Email:',
        },
    },
}
export default site;