<template>
  <f7-page name="addfund" @page:init="loadBalances">
    <f7-navbar :title="$root.setLang('add_fund')" :back-link="$root.setLang('back')"></f7-navbar>
    <f7-block-title>{{$root.setLang('balance_payment_method_automatic')}}</f7-block-title>

    <f7-block strong>
      <p>{{$root.setLang('balance_payment_method_automatic_des')}}</p>
    </f7-block>
    <f7-block>
      <f7-row>
        <f7-col width="100" medium="33">
          <f7-col width="100">
            {{$root.setLang('payment_addfund_choose_the_wallet_you_want_to_top_up')}}
              <f7-list no-hairlines-md style="margin: 5px 0;">
                <f7-list-item
                ref="select_service"
                :title="$root.setLang('wallet')" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                  <select 
                  id="select_service"
                  name="select_service"
                  v-model="service"
                  >
                    <option  v-for="(balance, index) in your_balance" :key="index" 
                    :value="balance._id"
                    :selected="service == balance._id"
                    >
                    {{ $root.getNameService(balance.service_id)+' ( '+$root.setPrice(balance.value)+' )' }}
                  </option>

                  </select>
                </f7-list-item>
              </f7-list>
        </f7-col>
          <f7-col width="100">
              <f7-list no-hairlines-md style="margin: 5px 0;">
                
                <f7-list-item
                :ref="service"
                :title="$root.setLang('payment_method')" 
                smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true,on: { closed: checkMethod }}"
                @close="checkMethod"
                >
                  <select 
                  id="select_method"
                  name="select_method"
                  v-model="method"
                  >
              <!-- <option value="Payoneer" ng-selected="paymentData['Payoneer'].selected" ng-if="paymentData['Payoneer'].status">Payoneer</option>
              <option value="PerfectMoney" ng-selected="paymentData['PerfectMoney'].selected" ng-if="paymentData['PerfectMoney'].status">Perfect Money</option>
              <option value="Skrill" ng-selected="paymentData['Skrill'].selected" ng-if="paymentData['Skrill'].status">Skrill</option>
              <option value="Vietcombank" ng-selected="paymentData['Vietcombank'].selected" ng-if="paymentData['Vietcombank'].status">Vietcombank</option> -->
              <option
                  v-for="(payment, index) in paymentData"
                  :key="index"
                  :value="index"
                  v-if="payment.status"
                  ng-selected="payment.selected"
                  >
                  {{payment.bank_name}}
                </option>
                  </select>
                </f7-list-item>
              </f7-list>
        </f7-col>

          <f7-col width="100">
              <f7-list inset style="margin-right: 0; margin-left: 0;" class="payment_method_form">

                <f7-list-input
                  type="text"
                  placeholder="Your name"
                  disabled
                  :value="paymentData[method].id"
                >
                <f7-icon slot="media" ios="f7:creditcard" aurora="f7:creditcard" md="f7:creditcard"></f7-icon>
                </f7-list-input>
                
                <f7-list-input
                  type="text"
                  placeholder="Your name"
                  disabled
                  :value="paymentData[method].name"
                  v-if="['Bitcoin','USDTTRC20','Ethereum'].indexOf(method) == -1"
                >
                  <f7-icon slot="media" ios="f7:person_crop_circle" aurora="f7:person_crop_circle" md="f7:person_crop_circle"></f7-icon>
                </f7-list-input>

                <f7-list-input
                  type="textarea"
    resizable
                  class="payment_note"
                  :label="$root.setLang('payment_note')"
                  disabled
                  :value="service"
                  v-if="['Bitcoin','USDTTRC20','Ethereum'].indexOf(method) == -1"
                >
                  <f7-icon slot="media" ios="f7:qrcode" aurora="f7:qrcode" md="f7:qrcode"></f7-icon>
                </f7-list-input>
                <f7-list-input v-if="method == 'Vietcombank'||method == 'Momo'"
            type="number"
                  class="payment_note"
                  :label="$root.setLang('amount_price')"
                  :value="amount_price"
                  @input="amount_price = $event.target.value;checkMethod();"
                  clear-button
                >
                  <f7-icon slot="media" ios="f7:money_dollar_circle" aurora="f7:money_dollar_circle" md="f7:money_dollar_circle"></f7-icon>
                </f7-list-input>
                <f7-button v-clipboard:copy="paymentData[method].id"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  :style="'display: inline-block;position: absolute;top: 8px;border: 1px solid #e3e3e3;right: 10px;background: '+colo_qr+';'+(($root.darkTheme)?'color:#f7f7f7;':'')"
                  >Copy</f7-button>
                <f7-button v-clipboard:copy="paymentData[method].name"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  v-if="['Bitcoin','USDTTRC20','Ethereum'].indexOf(method) == -1"
                  :style="'display: inline-block;position: absolute;top: 52px;border: 1px solid #e3e3e3;right: 10px;background: '+colo_qr+';'+(($root.darkTheme)?'color:#f7f7f7;':'')"
                  >Copy</f7-button>
                <f7-button v-clipboard:copy="service"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  v-if="['Bitcoin','USDTTRC20','Ethereum'].indexOf(method) == -1"
                  :style="'display: inline-block;position: absolute;top: 96px;border: 1px solid #e3e3e3;right: 10px;background: '+colo_qr+';'+(($root.darkTheme)?'color:#f7f7f7;':'')"
                  >Copy</f7-button>
              </f7-list>
        </f7-col>
        </f7-col>

        
        <f7-col  width="100" medium="33"
        v-if="method == 'Vietcombank' && service != ''">
        {{$root.setLang('scan_qr_code')}}
        <div class="qrcodevcb" ref="qrcodevcb" :style="'background:'+colo_qr+';'"></div>
          <!-- <img id="img" :src="'https://api.vietqr.io/'+method+'/'+paymentData[method].id+'/'+amount_price+'/'+service+'/compact2.jpg?accountName='+paymentData[method].name" style="width:100%;" /> -->
        </f7-col>
        <f7-col  width="100" medium="33"
        v-if="method == 'Momo' && service != ''">
        {{$root.setLang('scan_qr_code')}}
          <div class="qrcode" ref="qrcode" :style="'background:'+colo_qr+';'"></div>
        </f7-col>

        <f7-col width="100" medium="33" v-if="checkAutoMethod() && ['Bitcoin','USDTTRC20','Ethereum'].indexOf(method) > -1">
            {{$root.setLang('payment_addfund_guide_for_crypto').replace('{method}', method.replace('Bitcoin','Btc').replace('Ethereum','Eth').replace('USDTTRC20','Usdt'))}}
              <div class="block-title" style="display: none;">Side By Side</div>
              <form class="list" id="Formwaitpayment" style="margin: 0;">
              <div class="timeline">
                <div class="timeline-item">
                  <div class="timeline-item-date">{{$root.setLang('crypto_step_1')}}<small></small></div>
                  <div class="timeline-item-divider"></div>
                  <div class="timeline-item-content" style="    width: 100%;">
                    <div class="timeline-item-inner">

                    <div class="timeline-item-title">{{$root.setLang('crypto_step_1_title')}}</div>
                    <div class="timeline-item-text">
                      {{$root.setLang('price_wait').replace('{method}', method.replace('Bitcoin','Btc').replace('Ethereum','Eth').replace('USDTTRC20','Usdt'))}}. {{$root.setLang('price_wait_placeholder').replace(/{method}/g, method.replace('Bitcoin','Btc').replace('Ethereum','Eth').replace('USDTTRC20','Usdt'))}}
                    </div>
                      <f7-list no-hairlines-md>
                        <f7-block-title style="display: none;"></f7-block-title>

                        <f7-list-input
                          type="number"
                          name="price"
                          min="0"
                          id="price"
                          :value="payment_wait"
                          @input="payment_wait = $event.target.value;"
                          :label="method.replace('Bitcoin','Btc').replace('Ethereum','Eth').replace('USDTTRC20','Usdt')+':'"
                          clear-button
                        >
                        </f7-list-input>
                      </f7-list>
                      <f7-button @click="Submitwaitpayment()" raised round active>{{$root.setLang('send')}}</f7-button>
                    </div>
                  </div>
                </div>
                <div class="timeline-item">
                  <div class="timeline-item-date">{{$root.setLang('crypto_step_2')}} <small></small></div>
                  <div class="timeline-item-divider"></div>
                  <div class="timeline-item-content">
                    <div class="timeline-item-inner"
                    v-clipboard:copy="paymentData[method].id"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                    >{{$root.setLang('crypto_step_2_text').replace('{method}', method.replace('Bitcoin','Btc').replace('Ethereum','Eth').replace('USDTTRC20','Usdt')).replace('{id}', paymentData[method].id).replace('{price}', payment_wait)}}</div>
                  </div>
                </div>
                <div class="timeline-item">
                  <div class="timeline-item-date">{{$root.setLang('crypto_step_3')}} <small></small></div>
                  <div class="timeline-item-divider"></div>
                  <div class="timeline-item-content">
                    <div class="timeline-item-inner">{{$root.setLang('crypto_step_3_text')}}</div>
                  </div>
                </div>
              </div>
            </form>
        </f7-col>
        <f7-col  width="100" medium="33"
        v-if="['Bitcoin','USDTTRC20','Ethereum'].indexOf(method) > -1">
        {{$root.setLang('scan_qr_code')}}
          <div class="qrcodecrypto" ref="qrcodecrypto" :style="'background:'+colo_qr+';'"></div>
        </f7-col>
        <f7-col width="100" :medium="(['Vietcombank','Momo'].indexOf(method) > -1)?'33':'66'" v-if="checkAutoMethod()  && ['Bitcoin','USDTTRC20','Ethereum'].indexOf(method) ==-1">
            {{$root.setLang('payment_addfund_enter_the_note_as_image_below_view_type')}}
          <f7-list no-hairlines-md style="margin: 5px 0;">
        <div :class="`payment_method_addfund payment_method_${method}`">
          <!-- <img data-ng-src="'static/images/'+method+'.png'" > -->
          <img v-bind:src="`static/images/${method}.png`" :style="'width: 400px;box-shadow: 0 5px 15px -6px black;'+(($root.darkTheme)?'filter: brightness(80%);':'')">
          <div class="control-label payment_method_addfund_note" >{{service}}</div>
        </div>

          <img src="static/images/Paypal.png" style="width: 0;opacity: 0;">
        <img src="static/images/Payoneer.png" style="width: 0;opacity: 0;">
        <img src="static/images/Skrill.png" style="width: 0;opacity: 0;">
        <img src="static/images/PerfectMoney.png" style="width: 0;opacity: 0;">
        <img src="static/images/Momo.png" style="width: 0;opacity: 0;">
        <img src="static/images/Vietcombank.png" style="width: 0;opacity: 0;">
        <img src="static/images/Techcombank.png" style="width: 0;opacity: 0;">
          </f7-list>
        </f7-col>
        <f7-col width="100" medium="66" v-if="!checkAutoMethod() && ['Bitcoin','USDTTRC20','Ethereum'].indexOf(method) > -1">
            {{$root.setLang('payment_addfund_guide_for_not_auto_payment')}}
        </f7-col>

      </f7-row>
    </f7-block>
  </f7-page>
</template>
<style type="text/css">
  .qrcode,.qrcodevcb,.qrcodecrypto{
    border: 1px solid #69696e;
    padding: 5px; 
    width: max-content;
        max-width: 100%;
  }
  .qrcode canvas,.qrcodevcb canvas,.qrcodecrypto canvas{
    width: 100%;
  }
</style>
<script>
  import * as QRCode from 'easyqrcodejs'
  export default {
    data() {
      const self = this;
      var method_select = "";
      var domain = location.host;
      var paymentData = self.$root.paymentData['*'];
      if(self.$root.paymentData[domain]){
        paymentData = self.$root.paymentData[domain];
      }
      for(var key in paymentData){
        if(method_select == ""){
          method_select = key;
        }
        if(paymentData[key].selected){
          method_select = key;
        }
      }
      var colo_qr = '#ffffff';
      if(self.$root.darkTheme){
        colo_qr = '#8e8e93'
      }
      var options = {
      text: "",
        width: 480,
        height: 480,
        colorDark : "#000000",
        colorLight : colo_qr,
        dotScale: 1,
        logo:"/static/images/logo_momo.png", // Relative address, relative to `easy.easy.qrcode.min.js`
        logoWidth:60, // widht. default is automatic width
        logoHeight:60, // height. default is automatic height
        logoBackgroundColor:colo_qr, // Logo backgroud color, Invalid when `logBgTransparent` is true; default is '#ffffff'
        logoBackgroundTransparent:false, // Whether use transparent image, default is false
      }
      // console.log(self.$root.paymentData)
      return {
        your_balance: [],
        method: method_select,
        method_last: method_select,
        service: "",
        paymentData: paymentData,
        amount_price: 1000000,
        qr_options: options,
        qr_vcb_options: options,
        momoQrCode: null,
        vcbQrCode: null,
        cryptoQrCode: null,
        typing_func: null,
        dataBank: self.$root.getBankDetail(),
        colo_qr: colo_qr,
        popupwaitpayment: false,
        payment_wait: 35,
      };
    },
    methods: {
      intialized() {
        console.log('initialized')
      },
      pageChanged(){
        const self = this;
        console.log('method',self.method)
      },
      checkMethod(){
        const self = this;
        const app = self.$f7;
        clearTimeout(self.typing_func);
        var time_out = 0;
        if(self.method_last != self.method){
          time_out = 100;
        }
        self.method_last = self.method;
        self.typing_func = setTimeout(function(){
          if(self.method == "Momo"){
            if(self.vcbQrCode != null){
              self.vcbQrCode.clear();
              self.vcbQrCode = null;
            }
            if(self.cryptoQrCode != null){
              self.cryptoQrCode.clear();
              self.cryptoQrCode = null;
            }
            self.buildMomo();
          }else if(self.method == "Vietcombank"){
            if(self.momoQrCode != null){
              self.momoQrCode.clear();
              self.momoQrCode = null;
            }
            if(self.cryptoQrCode != null){
              self.cryptoQrCode.clear();
              self.cryptoQrCode = null;
            }
            self.buildVcb();
          }else if(["Bitcoin","USDTTRC20","Ethereum"].indexOf(self.method) > -1){
            if(self.momoQrCode != null){
              self.momoQrCode.clear();
              self.momoQrCode = null;
            }
            if(self.vcbQrCode != null){
              self.vcbQrCode.clear();
              self.vcbQrCode = null;
            }
            self.buildCrypto();
          }else{
            if(self.momoQrCode != null){
              self.momoQrCode.clear();
              self.momoQrCode = null;
            }
            if(self.vcbQrCode != null){
              self.vcbQrCode.clear();
              self.vcbQrCode = null;
            }
            if(self.cryptoQrCode != null){
              self.cryptoQrCode.clear();
              self.cryptoQrCode = null;
            }
            
          }
        }, time_out)
      },
      openPayment(){
        const self = this;
        const app = self.$f7;
        // self.actionNew.close();
                  self.popupwaitpayment = true;
                  var order = app.form.convertToData('#Formwaitpayment');
                  self.getDataForm('#Formwaitpayment')
      },
      getDataForm(el){
        var self = this;
        var app = self.$f7;
        if(app && app.form){
          var formData = app.form.convertToData(el);
          if(formData && formData.amount && formData.service_id){
            // self.getPriceService(formData);
          }else{
            // self.order_price = self.$root.setPrice(0);
            // self.order_price_root = self.$root.setPrice(0);
          }
        }else{
          // self.order_price = self.$root.setPrice(0);
          //   self.order_price_root = self.$root.setPrice(0);
        }
      },
      Submitwaitpayment(){
        var self = this;
        var app = self.$f7;
        var price = self.payment_wait;
        var service_id = "";
        for (var i = 0; i < self.your_balance.length; i++) {
          if(self.service == self.your_balance[i]._id){
            service_id = self.your_balance[i].service_id._id;
          }
          
        }
        
        if(price > 0 && self.$root.services.indexOf(service_id) > -1){

        }else{
          return app.dialog.alert(self.$root.setLang('please_enter_correct_price'), self.$root.setLang('failed'));
          // return;
        }
        // console.log(price, service_id)
        app.dialog.progress(self.$root.setLang('registering_payment'));
                app.request.post(self.$root.$f7.params.apiurl, {action: 'waitpayment', price: price, service_id: service_id, method: self.method, supplier: self.$root.supplier, token: app.data.user.token}, function(data){
                  if(data && data.status == "success"){
                  }
                  setTimeout(() => {
                      self.$f7.dialog.close();

                      if(data && data.status == "success"){
                        app.dialog.alert(self.$root.setLang('registered_payment'), self.$root.setLang('success'));
                      }else{
                        app.dialog.alert(data.message, self.$root.setLang('request_had_been_failed'));
                      }
                    }, 500);
                },
                function(err){
                  try{
                    try{
                      app.dialog.alert(JSON.parse(err.response).message, self.$root.setLang('request_had_been_failed'));
                    }catch(e){
                      app.dialog.alert(err.response);
                    }
                    
                  }catch(e){
                    app.dialog.alert(err);
                  }
                    setTimeout(() => {
                      self.$f7.dialog.close();
                    }, 500);
                },'json');
      },
      buildCrypto(){
        const self = this;
        if(self.$refs.qrcodecrypto){
          self.qr_options.text = self.paymentData[self.method].id;
          self.qr_vcb_options.logo = "/static/images/logo_momo.png";
          self.qr_vcb_options.logo = self.$root.qr_logo;
          if(self.cryptoQrCode == null){
            self.cryptoQrCode = new QRCode(this.$refs.qrcodecrypto, self.qr_options)
          }else{
            self.cryptoQrCode.makeCode(self.qr_options.text)

          }
        }else{
          setTimeout(self.buildCrypto, 100);
        }
        
      },
      buildMomo(){
        const self = this;
        if(self.$refs.qrcode){
          self.qr_options.text = "2|99|"+self.paymentData[self.method].id+"|"+self.paymentData[self.method].name+"||0|0|"+self.amount_price+"|"+self.service;
          self.qr_vcb_options.logo = "/static/images/logo_momo.png";
          self.qr_vcb_options.logo = self.$root.qr_logo;
          if(self.momoQrCode == null){
            self.momoQrCode = new QRCode(this.$refs.qrcode, self.qr_options)
          }else{
            self.momoQrCode.makeCode(self.qr_options.text)

          }
        }else{
          setTimeout(self.buildMomo, 100);
        }
        
      },
      buildVcb(){
        const self = this;
        if(self.$refs.qrcodevcb){
          var crc_text = `${self.$root.qr_data.id1}${self.$root.qr_data.id2}${self.$root.qr_data.id3}${self.$root.qr_data.id4}${self.dataBank[self.method.toLowerCase().replace(/ /g,'')].bin}${self.$root.qr_data.id5}${self.paymentData[self.method].id}${self.$root.qr_data.id6}${self.amount_price.toString().length}${self.amount_price}${self.$root.qr_data.id7}${self.service}${self.$root.qr_data.id8}`;
          var data_crc = self.$root.createCRC(crc_text)
          self.qr_vcb_options.text = crc_text+data_crc;
          self.qr_vcb_options.logo = "/static/images/logo_vcb.png";
          self.qr_vcb_options.logo = self.$root.qr_logo;
          if(self.vcbQrCode == null){
            self.vcbQrCode = new QRCode(this.$refs.qrcodevcb, self.qr_vcb_options)
          }else{
            self.vcbQrCode.makeCode(self.qr_vcb_options.text)
          }
        }else{
          setTimeout(self.buildVcb, 100);
        }
      },
      checkAutoMethod(){
        const self = this;
        // console.log('method',self.paymentData[self.method])
        return self.paymentData[self.method].auto;
      },
      // loadMore(){
      //   const self = this;
      //   console.log(self.$root.setLang('key_lange', 'Order #{main_id} by {userApply} had been created.'))
      //   self.notifications = this.$f7.data.notifications;

      //   console.log(self.notifications)
      //   console.log('ok nha')
      // },
      loadBalances(callback) {
        const self = this;
        var app = self.$f7;
        try{
        // app.request.post(self.$root.$f7.params.apiurl, {action: 'getwalllet', supplier: self.$root.supplier, token: app.data.user.token}, function(data){
          // var your_balance = data.data;
          var your_balance = app.data.userbalance.balances;
          var your_balance_new = [];
          for (var i = 0; i < your_balance.length; i++) {
            if(self.$root.services.indexOf(your_balance[i].service_id._id) != -1){
              your_balance_new.push(your_balance[i])
            }
          }
          self.your_balance = your_balance_new;
          try{
            if(self.service == ''){
              self.service = self.your_balance[0]._id;
              for (var i = 0; i < self.your_balance.length; i++) {
                if(self.your_balance[i].service_id.id == 118){
                  self.service = self.your_balance[i]._id;
                  var textEl = self.$refs["select_service"].$el;
                  var myElement = textEl.querySelector('.item-after').innerHTML = self.$root.getNameService(self.your_balance[i].service_id)+' ( '+self.$root.setPrice(self.your_balance[i].value)+' )';
                  break;
                }
              }
            }
          }catch(e){}
        }catch(e){
          setTimeout(function(){
            self.loadBalances(callback)
          }, 200)
        }
        // },
        // function(err){
        //   self.your_balance = [];
        //   self.service = "";
        // },'json');
      },
      
      getPriceService(service_id){
        const self = this;
      for (var i = 0; i < self.userservices.length; i++) {
        if(self.userservices[i].service_id._id == service_id){
          var service_detail = self.getService(service_id);
          var unit = self.$root.setLang('general.1_unit', '1 unit');
          if(service_detail.service_group == 'liveview'){
            if(service_detail.id ==12){
              unit = self.$root.setLang('general.1_viewer_on_unlimited_time', '1 viewer/Unlimited time');
            }else{
              unit = self.$root.setLang('general.1_viewer_on_30_minutes', '1 viewer/30 minutes');
            }
            
          }
          if(service_detail.service_group == 'videoview'){
            unit = self.$root.setLang('general.1_view', '1 view');
          }
          if(service_detail.service_group == 'autoorder_live'){
            unit = self.$root.setLang('general.1_viewer_on_1_day', '1 viewer/1 day');
          }
          if(service_detail.service_group == 'autoorder_view'){
            unit = self.$root.setLang('general.1_view_on_1_day', '1 view/1 day');
          }
          return self.$root.setPrice(self.userservices[i].price)+'/'+unit;
          break;
        }
        
      }
      return self.$root.setLang('general.not_available', 'Not available');
    },
      getService(service_id){
        const self = this;
        for (var i = 0; i < self.services.length; i++) {
          if(self.services[i]._id == service_id || self.services[i]._id.toString() == service_id){
            return self.services[i];
          }
        }
        return false;
      },
    },
    created(){

      //   const self = this;
      //   self.$f7ready(() => {
      //   self.buildVcb();
      // })
    },
    mounted () {
      const self = this;
        self.$f7ready(() => {
        self.buildVcb();
      })
    },
  };
</script>
