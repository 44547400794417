<template>
  <f7-page name="contacts"
    :color-theme="$root.color_theme">
    <f7-navbar :title="$root.setLang('contacts_contacts_info')"></f7-navbar>
    <f7-block>
      <f7-row>
      	<f7-col width="100" v-if="getContact('phone') != ''">
      		{{$root.setLang('contacts_phone_zalo_whatsapp_telegram')}}:
            <f7-list no-hairlines-md style="margin: 5px 0;" class="payment_method_form">

                <f7-list-input
                  type="text"
                  placeholder="Your name"
                  disabled
                  :value="getContact('phone')"
                >
                <f7-icon slot="media" ios="f7:phone_circle" aurora="f7:phone_circle" md="f7:phone_circle"></f7-icon>
                </f7-list-input>
                <f7-button v-clipboard:copy="getContact('phone')"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  style="    display: inline-block;
                position: absolute;
                top: 8px;
                border: 1px solid #e3e3e3;
                right: 10px;"
                  >Copy</f7-button>
            </f7-list>
          </f7-col>
          <f7-col width="100" v-if="getContact('email') != ''">
            {{$root.setLang('contacts_email')}}:
            <f7-list no-hairlines-md style="margin: 5px 0;" class="payment_method_form">

                <f7-list-input
                  type="text"
                  placeholder="Your name"
                  disabled
                  :value="getContact('email')"
                >
                <f7-icon slot="media" ios="f7:envelope" aurora="f7:envelope" md="f7:envelope"></f7-icon>
                </f7-list-input>
                <f7-button v-clipboard:copy="getContact('email')"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  style="    display: inline-block;
                position: absolute;
                top: 8px;
                border: 1px solid #e3e3e3;
                right: 10px;"
                  >Copy</f7-button>
            </f7-list>
        </f7-col>
          <f7-col width="100" v-if="getContact('facebook') != ''">
            {{$root.setLang('contacts_facebook')}}:
            <f7-list no-hairlines-md style="margin: 5px 0;" class="payment_method_form">

                <f7-list-input
                  type="text"
                  placeholder="Your name"
                  disabled
                  :value="getContact('facebook')"
                >
                <f7-icon slot="media" ios="f7:logo_facebook" aurora="f7:logo_facebook" md="f7:logo_facebook"></f7-icon>
                </f7-list-input>
                <f7-button v-clipboard:copy="getContact('facebook')"
                  v-clipboard:success="$root.onCopySuccess"
                  v-clipboard:error="$root.onCopyError"
                  style="    display: inline-block;
                position: absolute;
                top: 8px;
                border: 1px solid #e3e3e3;
                right: 10px;"
                  >Copy</f7-button>
            </f7-list>
        </f7-col>

      </f7-row>
  </f7-block>
  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
      };
    },
    methods: {
      getContact(id){
        const self = this;
        var domain = location.host;
        var contacts = self.$root.contacts;
        var value = '';
        if(contacts['*'] && contacts['*'][id]){
          value = contacts['*'][id].id;
        }
        if(contacts[domain]){
          value = '';
          if(contacts[domain][id]){
            value = contacts[domain][id].id;
          }
        }
        return value;
        // console.log(value,location.host)
      },
    }
  }
</script>
