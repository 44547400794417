var site = {
	name: "Búp mắt Tiktok",
	supplier: '5c46edc1fdb2e5227423ddd1',
	lang_default: 'vn',
	managerSite: 'https://viewadbreaks38.com/',
  	service_alias: 'live_tiktok',
	services: ["64de11aae30c95146e9cd73f"],
  	paymentData: {
        'Vietcombank' : {
			'status': true,
			'selected': true,//set default show
			'id': '0201000664552',
			'name': 'Le Phi Long',
			'bank_name': 'Vietcombank',
			'min': 2.5,
			'auto': true,
        },
    },
    contacts: {
        'phone': {
            'id': '+84356810777',
            'name': 'Phone, Zalo, WhatsApp, Telegram:',
        },
        'facebook': {
            'id': 'https://www.facebook.com/sink.tc.5',
            'name': 'Facebook:',
              
        },
    },
}
export default site;