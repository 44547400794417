<template>
  <f7-page
    :color-theme="$root.color_theme">
    <f7-navbar title="404" :back-link="$root.setLang('back')"></f7-navbar>
    <f7-block strong>
      <p>{{$root.setLang('sorry')}}</p>
      <p>{{$root.setLang('your_link_access_not_found')}}</p>
    </f7-block>
  </f7-page>
</template>
<script>
  export default {};
</script>