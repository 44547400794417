<template>
  <f7-page name="catalog"  @page:init="onPageInit"
  ptr @ptr:refresh="loadRefresh"
  @init="loadMore"
    infinite
    :infinite-distance="200"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <f7-navbar title="Video view orders"></f7-navbar>
    <f7-block-title>Scroll bottom</f7-block-title>
    <!-- <f7-list>
      <f7-list-item v-for="(item, index) in items" :title="`Item ${item}`" :key="index"></f7-list-item>
    </f7-list> -->
    <f7-list media-list>

    <!-- <f7-list-item
      link="#"
      header="hihi"
      title="Yellow Submarine"
      after="$15"
      subtitle="Beatles"
      text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
    >
      <img slot="media" src="https://cdn.framework7.io/placeholder/people-160x160-1.jpg" width="80" />
    </f7-list-item> -->
    <!-- :header="`${order.user.username} - ${order.created}`" -->
    <!-- 
        :link="`/videovieworders/${order._id}/`" -->

      <f7-list-item
      swipeout
        v-for="(order, index) in orders"
        :key="index"
        :header="order.service_id.name"
        :after="`$${order.price}`"
        :title="order.video_id"
        :text="getHeader(order)"
        :header1="`${order.user.username} - ${order.created}`"
        >
        <f7-swipeout-actions right>
          <f7-swipeout-button @click="more(order)">More</f7-swipeout-button>
          <f7-swipeout-button @click="openLink(order)" color="blue">Edit</f7-swipeout-button>
          <f7-swipeout-button delete>Delete</f7-swipeout-button>
        </f7-swipeout-actions>

        <img slot="media" :src="`https://graph.facebook.com/${order.profile_id}/picture?type=large`" width="48" />
        <div slot="inner"><f7-badge color="deeporange">{{statusOrder(order.status)}}</f7-badge></div>
        <div slot="subtitle">
<f7-chip :text="order.amount" ></f7-chip>
<f7-chip outline :text="`Start ${order.start_count}`"></f7-chip>
<f7-chip outline :text="`Now ${order.delivery_count}`"></f7-chip>
          <!-- <f7-badge color="green">{{order.amount}}</f7-badge> -->
        </div>
        <!-- <div slot="root-start">Root Start</div>
      <div slot="root">Root End</div>
      <div slot="content-start">Content Start</div>
      <div slot="content">Content End</div>
      <div slot="media-start">Media Start</div>
      <div slot="media">Media</div>
      <span slot="after-start">After Start</span>
      <span slot="after">After End</span>
      <div slot="inner-start">Inner Start</div>
      <div slot="inner">Inner End</div>
      <div slot="before-title">Before Title</div>
      <div slot="after-title">After Title</div> -->
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
        orders: [],
        order_swipe: {},
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        allowInfinite: true,
        showPreloader: true,
      };
    },
    // on: {
    //   pageInit: function () {
    //     var self = this;
    //     var app = self.$f7;
    //     // request user data on page init
    //     app.request.get(app.data.apiurl+'videovieworderslist', (user) => {
    //       // update component state with new state
    //       self.$setState({
    //         user: user,
    //       });
    //     });
    //   },
    // },
    methods: {
      getHeader(order){
        const self = this;
        var d = new Date(order.created);
        return self.formatDate(d)+' by '+ order.user.username
      },
      formatDate(date) {
        var monthNames = [
          "Jan", "Feb", "Mar",
          "Apr", "May", "Jun", "Jul",
          "Aug", "Sep", "Oct",
          "Nov", "Dec"
        ];

        var minute = date.getMinutes();
        var hour = date.getHours();
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        return  hour + ':' +minute + ' ' +  monthNames[monthIndex]+ ' ' +day ;
      },

      loadMore() {
        const self = this;
        if (!self.allowInfinite) return;
        self.allowInfinite = false;
        // setTimeout(() => {
          if (self.orders.length >= 40) {
            self.showPreloader = false;
            return;
          }

          // const itemsLength = self.items.length;

          // for (let i = 1; i <= 20; i += 1) {
          //   self.items.push(itemsLength + i);
          // }
          self.loadData()
          self.allowInfinite = true;
        // }, 1000);
      },
      loadRefresh(done) {
        const self = this;
        self.orders = []
        self.loadData();
        done();
      },
      loadData(){
        var self = this;
        var app = self.$f7;
        // request user data on page init
        app.request.post(self.$root.$f7.params.apiurl+'videovieworderslist', {}, function(data){
          var orders = data.data;
          self.orders = self.orders.concat(orders);
        },
        function(err){
          console.log('err',err)
        },'json');
      },
      statusOrder(status){
        var list_status = ['Add','Active', 'Running', 'Completed','Cancelled','','Req Cancel','Pause'];
        if(list_status[status-1]){
          return list_status[status-1];
        }
        return "";
      },
      more(order) {
        const self = this;
        self.order_swipe = order;
        self.actions.open();
      },
      openLink(order) {
        const self = this;
        self.$f7router.navigate('/videovieworders/'+order._id, { transition: 'f7-cover' })
      },
      onPageInit() {
        const self = this;
        self.loadData()
        const app = self.$f7;
        self.actions = app.actions.create({
          buttons: [
            [
              {
                text: 'Here comes some optional description or warning for actions below',
                label: true,
              },
              {
                text: 'Action 1',
                onClick: function () {
                  console.log('order',self.order_swipe)
                  app.dialog.alert('Button2 clicked',self.order_swipe._id)
                }
              },
              {
                text: 'Action 2',
              },
            ],
            [
              {
                text: 'Cancel',
                bold: true,
              },
            ],
          ],
        });
      },
    },
    
    mounted() {
    },
  };
</script>