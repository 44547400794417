<template>
  <f7-page name="payments" @page:init="loadRefresh"
  ptr @ptr:refresh="loadRefresh"
  infinite
    :infinite-distance="200"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <f7-navbar :title="$root.setLang('payments_history')"></f7-navbar>

    <f7-block-title>{{$root.setLang('your_payments')}}
      <f7-button 
      :class="{button_reload:is_loading==true}"
       icon-f7="arrow_2_circlepath"  @click="loadRefresh()" style="width: 50px;float: right;"></f7-button></f7-block-title>
    <f7-list media-list>
    <f7-list-item
        v-for="(payment, index) in $f7.data.payments"
        :key="index"
        :title="setLanguagePayment(payment)"
        :after="`${(payment && payment.price)? $root.setPricePayment(payment.price):''}`"
        :header="(payment && payment.service_id && payment.service_id.name)? $root.getNameService(payment.service_id): ''"
        :subtitle="$root.setLang('balance_before_order')+$root.setPrice(payment.balance_current)"
        :text="(payment)?formatDate(payment.created): ''"
        >
        <img slot="media" :src="getAvatar(payment)" width="30" style="border-radius: 50%;" />
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
        payments: [],
        allowInfinite: true,
        showPreloader: true,
        is_loading: false,
        page: 2,
      };
    },
    methods: {
      intialized() {
        console.log('initialized')
      },
      // loadMore(){
      //   const self = this;
      //   console.log(self.$f7.data.setLanguage('key_lange', 'Order #{main_id} by {userApply} had been created.'))
      //   self.payments = this.$f7.data.payments;

      //   console.log(self.payments)
      //   console.log('ok nha')
      // },
      onPageInit() {
        const self = this;
        self.$f7.data.payments = [];
        self.loadRefresh();
      },
      loadRefresh(done) {
        const self = this;
        self.payments = []
        self.is_loading = true;
        self.showPreloader = true;
        self.page = 1;
        // self.loadData();
        // done();
        self.loadMorePayments(self.page, function(payments){
              if(payments){
                if(payments.length == 0){
                  self.showPreloader = false;
                }
                self.$f7.data.payments = payments;
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
              if(typeof done === "function"){
                done();
              }
            })
      },
      loadMore() {
        const self = this;
        if(self.showPreloader && !self.is_loading){
          self.is_loading = true;
          if (!self.allowInfinite) return;
          self.allowInfinite = false;
            if (self.$f7.data.payments.length >= 5000) {
              self.showPreloader = false;
              return;
            }
            // self.loadData()
            self.loadMorePayments(self.page, function(payments){
              if(payments){
                if(payments.length == 0){
                  self.showPreloader = false;
                }
                self.$f7.data.payments = self.$f7.data.payments.concat(payments);
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
            })
            
        }
      },
      loadMorePayments(page, callback) {
        const self = this;
        var app = self.$f7;
        app.request.post(self.$root.$f7.params.apiurl, {action: 'paymentslist', supplier: self.$root.supplier, token: app.data.user.token, service_ids: self.$root.services.join(','),page: page}, function(data){
          var payments = data.data;
          callback(payments);
        },
        function(err){
          callback(false);
        },'json');
      },
      setLanguagePayment(payment){
        const self = this;
        return payment.name;
        // return self.$f7.data.setLanguageNotification(payment);//self.$f7.data.setLanguageNotification(payments)
      },
      getAvatar(payment){
        const self = this;
        if(payment){
          return self.$root.$f7.params.apiurlRoot+'/'+payment.userApply.profileImageURL
        }else{
          return '';
        }
      },
      formatDate(created){
        const self = this;
        var d = new Date(created);
        return self.$root.formatDate(d);//self.$f7.data.setLanguageNotification(payments)
      },
      // setPricePayment(price){
      //   const self = this;
      //   return self.$root.setPricePayment(price);//self.$f7.data.setLanguageNotification(payments)
      //   // return self.$root.setPriceNotification(price);//self.$f7.data.setLanguageNotification(payments)
      // }
    },
    mounted () {
    },
  };
</script>