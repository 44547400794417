<template>
  <f7-page name="promotions"  @page:init="loadRefresh"
  ptr @ptr:refresh="loadRefresh"
  >

    <f7-navbar :title="$root.setLang('offer_for_you')" :back-link="$root.setLang('back')"></f7-navbar>

    <f7-block-title>{{$root.setLang('click_the_line_to_copy_discount_code')}}</f7-block-title>
    <f7-list media-list>
    <f7-list-item
        v-for="(promotion, index) in promotions"
        :key="index"
        :subtitle="``"
        :title="promotion.code"
        :header="$root.getDesPromotion(promotion, false)"
        :text="$root.setLang('expiry_date_until_end_of_day')+` `+$root.formatDatePromotion(promotion.expired_date)"
        :badge="$root.getCoverPromotion(promotion)"
        badge-color="deeporange"
        @click="$root.copyText(promotion.code)"
        >
        <div slot="before-title" style="    margin-right: 10px;">{{$root.setLang('discount_code')}}: </div>
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
        promotions: [],
        allowInfinite: true,
        showPreloader: true,
        is_loading: false,
        page: 2,
      };
    },
    methods: {
      intialized() {
        console.log('initialized')
      },
      // loadMore(){
      //   const self = this;
      //   console.log(self.$root.setLang('key_lange', 'Order #{main_id} by {userApply} had been created.'))
      //   self.notifications = this.$f7.data.notifications;

      //   console.log(self.notifications)
      //   console.log('ok nha')
      // },
      loadRefresh() {
        const self = this;
        if(self.showPreloader && !self.is_loading){
          self.is_loading = true;
          if (!self.allowInfinite) return;
          self.allowInfinite = false;
            if (self.promotions.length >= 5000) {
              self.showPreloader = false;
              return;
            }
            // self.loadData()
            self.$root.loadMorePromotions(function(promotions){
              if(promotions){
                if(promotions.length == 0){
                  self.showPreloader = false;
                }
                self.promotions = promotions;
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
            })
            
        }
      },
    },
    mounted () {
    },
  };
</script>