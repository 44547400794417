<template>
  <f7-page name="services"  @page:init="loadRefresh"
  ptr @ptr:refresh="loadRefresh"
  >

    <f7-navbar :title="$root.setLang('service_list')" :back-link="$root.setLang('back')"></f7-navbar>

    <f7-block-title>{{$root.setLang('price_list_for_you')}}</f7-block-title>
    <f7-list media-list>
    <f7-list-item
        v-for="(service, index) in services"
        :key="index"
        :title="$root.getNameService(service)"
        :after="getPriceService(service._id)"
        >
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
        services: [],
        userservices: [],
        allowInfinite: true,
        showPreloader: true,
        is_loading: false,
        page: 2,
      };
    },
    methods: {
      intialized() {
        console.log('initialized')
      },
      // loadMore(){
      //   const self = this;
      //   console.log(self.$root.setLang('key_lange', 'Order #{main_id} by {userApply} had been created.'))
      //   self.notifications = this.$f7.data.notifications;

      //   console.log(self.notifications)
      //   console.log('ok nha')
      // },
      loadRefresh() {
        const self = this;
        if(self.showPreloader && !self.is_loading){
          self.is_loading = true;
          if (!self.allowInfinite) return;
          self.allowInfinite = false;
            if (self.services.length >= 5000) {
              self.showPreloader = false;
              return;
            }
            // self.loadData()
            self.loadMoreNotifications(function(services, userservices){
              if(services){
                if(services.length == 0){
                  self.showPreloader = false;
                }
                self.services = services;
                self.userservices = userservices;
                self.is_loading = false;
                self.page++;
              }else{
                self.is_loading = false;
              }
              self.allowInfinite = true;
            })
            
        }
      },
      loadMoreNotifications(callback) {
        const self = this;
        var app = self.$f7;
        app.request.post(self.$root.$f7.params.apiurl, {action: 'serviceslist', supplier: self.$root.supplier, token: app.data.user.token}, function(data){
          var services = data.data;
          var userservices = data.dataUser;
          var services_new = [];
          for (var i = 0; i < services.length; i++) {
            if(self.$root.services.indexOf(services[i]._id) != -1){
              services_new.push(services[i])
            }
          }
          services = services_new;
          callback(services, userservices);
        },
        function(err){
          callback(false, false);
        },'json');
      },
      
      getPriceService(service_id){
        const self = this;
        // console.log(self.userservices)
      for (var i = 0; i < self.userservices.length; i++) {
        if(self.userservices[i].service_id._id == service_id && self.userservices[i].status == 1){
          var service_unit = 1;
          var service_detail = self.getService(service_id);
          var unit = self.$root.setLang('general.1_unit', '1 đơn vị');
          if(service_detail.service_group == 'liveview'){
            if(service_detail.id ==12){
              unit = self.$root.setLang('general.1_viewer_on_unlimited_time', '1 mắt');
            }else{
              service_unit = 1/30;
              unit = self.$root.setLang('general_1_viewer_on_30_minutes', '1 mắt/1 phút');
            }
            
          }
          if(service_detail.service_group == 'videoview'){
            unit = '';
            if(service_detail.id == 68){
              service_unit = 10000;
            }else if(service_detail.id == 111){
              service_unit = 10000;
            }else if(service_detail.id == 118){
              service_unit = 500;
            }else if(service_detail.id == 60){
              service_unit = 1000;
            }else if([115,116].indexOf(service_detail.id) > -1){
              unit = self.$root.setLang('general_1_follow', '1 follow');
            }else if([117].indexOf(service_detail.id) > -1){
              unit = self.$root.setLang('general_1_reaction', '1 reaction');
            }else if(service_detail.id == 15){
              unit = self.$root.setLang('general_1_reel', '1 reel');
            }else if(service_detail.id == 16){
              unit = self.$root.setLang('general_1_reel', '1 reel');
            }else{
              unit = self.$root.setLang('general_1_view', '1 lượt xem');
            }
            
          }
          if(service_detail.service_group == 'autoorder_live'){
            unit = self.$root.setLang('general.1_viewer_on_1_day', '1 mắt/1 ngày');
          }
          if(service_detail.service_group == 'autoorder_view'){
            unit = self.$root.setLang('general.1_view_on_1_day', '1 lượt xem/1 ngày');
          }
          if(unit == ""){
            return self.$root.setPrice(self.userservices[i].price*service_unit);
          }
          if(unit != "" && service_unit != 1){
            return self.$root.setPrice(self.userservices[i].price*service_unit)+'/'+unit;
          }
          return self.$root.setPrice(self.userservices[i].price)+'/'+unit;
          break;
        }
        
      }
      return self.$root.setLang('this_service_not_active', 'Chưa kích hoạt cho bạn');
    },
      getService(service_id){
        const self = this;
        for (var i = 0; i < self.services.length; i++) {
          if(self.services[i]._id == service_id || self.services[i]._id.toString() == service_id){
            return self.services[i];
          }
        }
        return false;
      },
    },
    mounted () {
    },
  };
</script>